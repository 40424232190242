import {
  IRelationshipFieldMany,
  RelationshipFieldMany
} from "./RelationshipFieldMany";

export class MultipleFileField extends RelationshipFieldMany {
  public static formComponentName: string = "FormMultipleFileField";
  public static viewComponentName: string = "ViewMultipleFileField";
  public static viewComponentProps = {
    cols: 12
  };
  public static formComponentProps = {
    cols: 12
  };

  protected static icon = "mdi-file-multiple-outline";
}
