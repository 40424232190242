import { CrudModel } from "../CrudModel";
import { DateField } from "./DateField";
import { FieldGroupField, IFieldGroupField } from "./FieldGroupField";

export enum DateRangeFieldsEnum {
  STARTDATE,
  ENDDATE
}
export interface IDateRangeField extends IFieldGroupField {
  disabledSubFields?: DateRangeFieldsEnum[];
}
export class DateRangeField extends FieldGroupField {
  public static fieldName = "DateRangeField";
  public static subFieldDefs = [
    {
      subFieldId: DateRangeFieldsEnum.STARTDATE,
      type: DateField,
      opts: {
        property: "start_date",
        label: "Start Date"
      }
    },
    {
      subFieldId: DateRangeFieldsEnum.ENDDATE,
      type: DateField,
      opts: {
        property: "end_date",
        label: "End Date"
      }
    }
  ];

  constructor(opts: IDateRangeField, model: CrudModel) {
    super(opts, model);

    if (typeof opts.label === "undefined") this._label = "Date Range";
  }
}
