import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { LongTextField } from "../plugins/Cruxtify/field-types";
import { DateTimeField } from "../plugins/Cruxtify/field-types/DateTimeField";
import { NumberField } from "../plugins/Cruxtify/field-types/NumberField";
import { RelationshipField } from "../plugins/Cruxtify/field-types/RelationshipField";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";

import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

export class Review extends CrudModel {
  protected static _typeLabel = "Review";
  protected static asProperty = "review";
  public static api = {
    path: "reviews",
  };
  protected static routeBase = "reviews";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "review",
        rules: ["required"],
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "rating",
        label: "Rating",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "letterboxd_id",
        label: "Letterboxd ID",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Author",
        relatedModel: "User",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "movie",
        label: "Movie",
        relatedModel: "Movie",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },

    {
      type: LongTextField,
      opts: {
        property: "review",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "rating",
      },
    },
    {
      type: StringField,
      opts: {
        property: "letterboxd_id",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "user",
        isPrimaryLabelField: 2,
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "movie",
        isPrimaryLabelField: 1,
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["screened_at", "title", "theme", "user", "phone", "user"],
      enableSearch: true,
      enableDynamicFilters: true,
      dynamicFilterOptions: {
        filterFields: [
          "screened_at",
          "title",
          "theme",
          "user",
          "phone",
          "user",
          "reason",
          "year",
          "created_at",
          "updated_at",
        ],
      },
      defaultSort: [
        {
          field: "screened_at",
          order: "desc",
        },
      ],
    },
  ];
}
