
import { CrudModel } from "../../CrudModel";

export default {
  data() {
    return {};
  },
  props: {
    model: {
      type: CrudModel,
      required: true
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onConfirm() {
      this.model.hydrate();
      this.$emit("confirm");
    }
  }
};
