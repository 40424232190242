import { DynamicFilter, DynamicFilterOperator } from "../DynamicFilter";

export class DateTimeFilter extends DynamicFilter {
  public operatorLabelsMap = {
    greaterThan: "is after",
    lessThan: "is before"
  };

  public disabledOperators: DynamicFilterOperator[] = [
    "contains",
    "notContains"
  ];
}
