
import { FieldGroupField } from "../../field-types/FieldGroupField";

export default {
  props: {
    field: {
      type: FieldGroupField,
      required: true,
    },
  },
};
