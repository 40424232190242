export default {
  required: {
    message: "This field is required",
    validator: function (value) {
      if (Array.isArray(value)) return value.length > 0;

      if (typeof value === "string") return value.trim().length > 0;

      return value || value === false || value === 0;
    },
  },
  htmlRequired: {
    message: "This field is required",
    validator: function (value) {
      if (!value) return false;

      // remove html from string
      value = value.replace(/<[^>]*>?/gm, "");
      return value.trim().length > 0;
    },
  },
  mustBeTrue: {
    message: "Required",
    validator: function (value) {
      return value;
    },
  },
  usPhone: {
    message: "This must be a valid US phone number",
    validator: function (number) {
      if (!number) return true;
      return /^1?\d{10}$/.test(number.toString().replace(/\D/g, ""));
    },
  },
};
