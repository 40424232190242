import { CrudField, ICrudField } from "../CrudField";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { CrudModel } from "../CrudModel";
import { DateProperty, DateTimeProperty } from "../property-types";
dayjs.extend(relativeTime);
dayjs.extend(utc);

export interface IDateTimeField extends ICrudField {
  displayFormat?: string;
  displayRelative?: boolean;
}
export class DateTimeField extends CrudField {
  public static fieldName = "DateTimeField";
  public static formComponentName: string = "form-date-time-field";
  protected static readonly defaultPropertyType = DateTimeProperty;
  public displayRelative = false;

  protected static requiresDetachedLabel: boolean = true;
  protected static icon: string = "mdi-calendar-clock";

  constructor(opts: IDateTimeField, model: CrudModel) {
    super(opts, model);

    if (typeof opts.displayRelative !== "undefined")
      this.displayRelative = opts.displayRelative;
  }

  public static now() {
    return dayjs.utc().toDate();
  }
}
