import { CrudField, CrudFieldQuery, ICrudField } from "../CrudField";
import { CrudModel, CrudModelType } from "../CrudModel";
import { CollectionLayoutOpts } from "../layouts/CollectionLayout";
import { RelationshipPropertyMany } from "../property-types";

export interface IRelationshipFieldMany extends ICrudField {
  collectionLayoutId?: string;
  collectionLayoutOpts?: Partial<CollectionLayoutOpts>;
  relatedModel: CrudModelType;
  hideFilters?: boolean;
}
export class RelationshipFieldMany extends CrudField {
  public static fieldName = "RelationshipFieldMany";
  public static formComponentName: string =
    "form-relationship-field-many-select";
  public static viewComponentName: string =
    "view-relationship-field-many-labels";
  protected static readonly defaultPropertyType = RelationshipPropertyMany;
  protected static requiresDetachedLabel = true;

  public hideFilters: boolean = true;
  public collectionLayoutId: string = "";
  public collectionLayoutOpts?: Partial<CollectionLayoutOpts>;
  constructor(opts: IRelationshipFieldMany, model: CrudModel) {
    super(opts, model);

    if (typeof opts.collectionLayoutId !== "undefined")
      this.collectionLayoutId = opts.collectionLayoutId;

    if (typeof opts.collectionLayoutOpts !== "undefined")
      this.collectionLayoutOpts = opts.collectionLayoutOpts;

    if (typeof opts.hideFilters !== "undefined")
      this.hideFilters = opts.hideFilters;

    // defaults
    if (typeof opts.cols === "undefined") this.cols = 12;
  }

  public get collection() {
    return (this.property as RelationshipPropertyMany).collection;
  }

  private _stripRootField(fieldString: string) {
    const fieldPieces = fieldString.split(".");
    fieldPieces.shift();
    return fieldPieces.join(".");
  }

  private _getRootField(fieldString: string) {
    return fieldString.split(".")[0];
  }

  public findFields(
    fieldArg: CrudFieldQuery[] | CrudFieldQuery
  ): CrudField[] | undefined {
    if (!Array.isArray(fieldArg)) fieldArg = [fieldArg];

    if (super.findFields(fieldArg)) return [this];

    if (fieldArg.some((field) => field == this.id)) return [this];

    const fieldStringsWithoutRootField = fieldArg
      .filter((arg) => typeof arg === "string")
      .filter((stringArg) => this._getRootField(stringArg as string) == this.id)
      .map((stringArg) => this._stripRootField(stringArg as string));

    return fieldStringsWithoutRootField.length > 0
      ? (this.property as RelationshipPropertyMany).collection.model
          .findFields(fieldStringsWithoutRootField)
          .filter((field) => field)
      : undefined;
  }

  public getRelativeLabel(fieldQuery: CrudFieldQuery, prefix = "") {
    const fields = this.findFields(fieldQuery);

    if (!fields) return "";
    if (fields.length > 1)
      console.error(
        `RelFieldMany.getRelativeLabel() returned more than 1 result.
        Check for duplicated property names or use a more specific query.`,
        fieldQuery,
        fields
      );

    const field = fields.pop() as CrudField;
    const thisLabel = super.getRelativeLabel(fieldQuery, prefix);
    if (field === this) return thisLabel;

    return field.getRelativeLabel(
      this._stripRootField(fieldQuery as string),
      thisLabel
    );
  }
}
