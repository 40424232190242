
import { RelationshipField } from "../../field-types/RelationshipField";

export default {
  data() {
    return {
      value: null,
    };
  },
  props: {
    field: {
      type: RelationshipField,
      required: true,
    },
    isReadMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setFieldInitalValue() {
      const propVal = this.field.property.get(true);
      if (!propVal) return;

      this.value = {
        text: this.field.property.getInstanceLabel(),
        value: propVal,
        model: this.field.property.modelInstance,
      };
    },
  },
  computed: {
    showValueLink() {
      return (
        !this.isReadMode &&
        this.value &&
        this.value.model &&
        !this.value.model.isNew
      );
    },
  },
  watch: {
    "field.property": {
      // deep: true,
      immediate: true,
      handler() {
        if (this.field.property.get(true)) {
          this.field.property.hydrate().then(() => {
            this.setFieldInitalValue();
          });
        } else {
          this.value = null;
        }
      },
    },
  },
};
