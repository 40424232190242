import { CrudField } from "./CrudField";
import { CrudModel } from "./CrudModel";

export enum UserRole {
  SuperAdmin = "SuperAdmin",
  SuperUser = "SuperUser",
  EntityOwner = "EntityOwner",
  NonManager = "NonManager",
  LoggedIn = "LoggedIn",
  Guest = "Guest",
}
export enum UserPermission {
  Edit = 3,
  New = 2,
  EditOnly = 1.5,
  Read = 1,
  Hidden = 0,
}
export interface PermissableEntity {
  getUserId(): number | null;
  id: number | null;
  isNew: boolean;
}
export type IUserPermission =
  | UserPermission
  | ((permissableEntity: PermissableEntity) => UserPermission);

export interface IUserPermissions {
  [UserRole.SuperAdmin]?: IUserPermission;
  [UserRole.SuperUser]?: IUserPermission;
  [UserRole.EntityOwner]?: IUserPermission;
  [UserRole.NonManager]?: IUserPermission;
  [UserRole.LoggedIn]?: IUserPermission;
  [UserRole.Guest]?: IUserPermission;
}
export class UserPermissions {
  [UserRole.SuperAdmin]?: IUserPermission;
  [UserRole.SuperUser]?: IUserPermission;
  [UserRole.EntityOwner]?: IUserPermission;
  [UserRole.NonManager]?: IUserPermission;
  [UserRole.LoggedIn]?: IUserPermission;
  [UserRole.Guest]?: IUserPermission;

  protected get _classRef(): typeof UserPermissions {
    return Object.getPrototypeOf(this).constructor;
  }
  protected static $nuxt;
  protected get $nuxt() {
    return this._classRef.$nuxt;
  }
  public static setNuxtContext(context) {
    this.$nuxt = context;
  }

  constructor(
    opts: IUserPermissions | IUserPermission,
    fallbackPermissionArg?: IUserPermission
  ) {
    const fallbackPermission = fallbackPermissionArg
      ? fallbackPermissionArg
      : typeof opts === "number"
      ? opts
      : false;
    if (fallbackPermission) {
      // it's a single UserPermission, apply to all roles
      this[UserRole.SuperAdmin] = fallbackPermission;
      this[UserRole.SuperUser] = fallbackPermission;
      this[UserRole.EntityOwner] = fallbackPermission;
      this[UserRole.NonManager] = fallbackPermission;
      this[UserRole.LoggedIn] = fallbackPermission;
      this[UserRole.Guest] = fallbackPermission;
    }
    if (typeof opts !== "number") {
      Object.keys(opts).forEach((key) => {
        if (typeof opts[key] !== "undefined") this[key] = opts[key];
      });
    }
  }

  private getNearestDefinedUserType(permissableEntity?: PermissableEntity) {
    if (!this.$nuxt.$auth.loggedIn) return UserRole.Guest;

    if (!permissableEntity) return UserRole.LoggedIn;
    const entityUserId = permissableEntity.getUserId();

    if (this.$nuxt.$auth.user.is_admin && this[UserRole.SuperAdmin])
      return UserRole.SuperAdmin;

    if (this.$nuxt.$auth.user.is_super_user && this[UserRole.SuperUser])
      return UserRole.SuperUser;

    if (this.$nuxt.$auth.user.id == entityUserId && this[UserRole.EntityOwner])
      return UserRole.EntityOwner;

    return UserRole.LoggedIn;
  }

  private getUserPermission(
    model?: CrudModel,
    field?: CrudField
  ): UserPermission {
    const userType = this.getNearestDefinedUserType(model);

    let userPermission;
    if (typeof this[userType] === "undefined")
      userPermission = UserPermission.Hidden;
    else if (typeof this[userType] === "function")
      userPermission = (this[userType] as Function)(model, field);
    else userPermission = this[userType];

    return userPermission;
  }

  public isVisibleToUser(model?: CrudModel, field?: CrudField) {
    return this.getUserPermission(model, field) !== UserPermission.Hidden;
  }

  public userCanCreateNew(model?: CrudModel, field?: CrudField) {
    const permission = this.getUserPermission(model, field);
    return (
      permission == UserPermission.Edit ||
      (model && permission == UserPermission.New && model.isNew)
    );
  }

  public isReadonlyToUser(model?: CrudModel, field?: CrudField): boolean {
    const permission = this.getUserPermission(model, field);
    return !!(
      permission == UserPermission.Read ||
      (model && permission == UserPermission.New && !model.isNew)
    );
  }
}

export interface HasUserPermissions {
  isReadonlyToUser(user?, model?: PermissableEntity): boolean;
  isVisibleToUser(user?, model?: PermissableEntity): boolean;
}
