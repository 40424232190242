import { DateTimeProperty } from "./DateTimeProperty";

import dayjs, { OpUnitType } from "dayjs";
dayjs.extend(require("dayjs/plugin/timezone"));

export class DateProperty extends DateTimeProperty {
  public displayFormat: string = "M/D/YYYY";

  public coerceValue(val: unknown): any {
    if (!val) return null;

    return dayjs.utc(val as string);
  }

  protected static compareResolution: OpUnitType = "day";

  public get serializedValue() {
    return this.value ? this.value.toISOString().substr(0, 10) : this.value;
  }
}
