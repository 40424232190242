import { CrudModel } from "../CrudModel";
import { ICrudField } from "../CrudField";
import { IStringField, StringField } from "./StringField";

export interface ILongTextField extends IStringField {
  rows?: number;
}
export class LongTextField extends StringField {
  public static fieldName = "LongTextField";
  public static formComponentName: string = "form-long-text-field";

  protected rows = 3;

  constructor(opts: ILongTextField, model: CrudModel) {
    super(opts, model);

    if (typeof opts.rows !== "undefined") this.rows = opts.rows;

    // defaults
    if (typeof opts.cols === "undefined") this.cols = 12;
  }

  public get formComponentProps() {
    const props = super.formComponentProps;

    props.rows = this.rows;

    return props;
  }
}
