
export default {
  data() {
    return {
      values: [],
      listLimit: 5
    };
  },
  props: {
    url: {
      type: String,
      required: true
    },
    linkClass: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    allClasses() {
      const requiredClasses = {
        "relation-chip-link": true
      };

      return {
        ...requiredClasses,
        ...this.linkClass
      };
    }
  }
};
