import { CrudField, CrudFieldQuery } from "../CrudField";
import { CrudFilter, ICrudFilter } from "../CrudFilter";
import { CrudModelType } from "../CrudModel";
import { ChoiceField } from "../field-types/ChoiceField";
import { SelectField } from "../field-types/SelectField";
import {
  BooleanProperty,
  DateProperty,
  DateTimeProperty,
  NumberProperty,
  PhoneProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
} from "../property-types";
import { DynamicFilter, DynamicFilterOpts } from "./DynamicFilter";
import { BooleanFilter } from "./dynamic-filters/BooleanFilter";
import { DateTimeFilter } from "./dynamic-filters/DateTimeFilter";
import { NumericFilter } from "./dynamic-filters/NumericFilter";
import { PhoneFilter } from "./dynamic-filters/PhoneFilter";
import { RelationshipFilter } from "./dynamic-filters/RelationshipFilter";
import { RelationshipManyFilter } from "./dynamic-filters/RelationshipManyFilter";
import { SelectFilter } from "./dynamic-filters/SelectFilter";

export interface IDynamicFilterController extends ICrudFilter {
  model: CrudModelType;
  dynamicFilterOptions?: DynamicFilterOptions;
}

export type DynamicFilterOptions = {
  filterFields?: CrudFieldQuery[];
};

export type FieldOption = {
  field: CrudField;
  label: string;
};

export class DynamicFilterController extends CrudFilter {
  public isHidden: boolean = true; // so it doesn't get rendered w/ other filters

  protected dynamicFilters: DynamicFilter[] = [];
  protected model: CrudModelType;
  protected _fields: CrudField[] = [];
  protected _fieldsById: Record<string, CrudField> | undefined;

  constructor(opts: IDynamicFilterController) {
    super(opts);

    this.model = opts.model;
    if (opts.dynamicFilterOptions?.filterFields) {
      this._fieldsById = this.model.findFieldsByRelativeId(
        opts.dynamicFilterOptions.filterFields
      );

      // report unresolved fields
      Object.keys(this._fieldsById).forEach((id) => {
        if (this._fieldsById && !this._fieldsById[id]) {
          console.warn(
            `DynamicFilterController: Unable to find field with relative id "${id}"`
          );
        }
      });

      this._fields = Object.values(this._fieldsById).filter((f) => f);
    } else {
      this._fields = this.model.getVisibleFields();
    }
  }

  public get query(): object {
    return { [this.queryName]: this.serializedValue };
  }

  public setQuery(encodedValue: any) {
    const decodedValue = JSON.parse(atob(encodedValue));

    decodedValue.forEach((filterValue) => {
      const fieldOption = this.findFieldOption(filterValue.p);

      if (!fieldOption) return;

      this.addFilter(fieldOption.field, {
        label: fieldOption.label,
        value: filterValue.v,
        operator: filterValue.o,
      });
    });
  }

  public get filterValues(): any[] {
    return this.dynamicFilters
      .map((f) => f.serializedValue)
      .filter((v) => typeof v !== "undefined");
  }

  public get serializedValue(): string | undefined {
    if (!this.filterValues.length) return undefined;

    return btoa(JSON.stringify(this.filterValues));
  }

  public isSet(): boolean {
    return this.filterValues.length > 0;
  }

  public clear() {
    this.dynamicFilters = [];
  }

  public addFilter(field: CrudField, filterOpts?: Partial<DynamicFilterOpts>) {
    if (this.hasFilter(field)) return;

    let filterType = DynamicFilter;

    // determine type of filter to use
    if (field.isOfType(SelectField))
      if (
        (field as ChoiceField).items.every(
          (item) =>
            !isNaN(parseFloat(item.value)) && isFinite(item.value as any)
        )
      )
        // numeric choice field
        filterType = NumericFilter;
      // regular select filter
      else filterType = SelectFilter;
    else if (field.property.isOfType(NumberProperty))
      filterType = NumericFilter;
    // dates
    else if (field.property.isOfType(DateTimeProperty))
      filterType = DateTimeFilter;
    else if (field.property.isOfType(DateProperty)) filterType = DateTimeFilter;
    // bool
    else if (field.property.isOfType(BooleanProperty))
      filterType = BooleanFilter;
    // phone
    else if (field.property.isOfType(PhoneProperty)) filterType = PhoneFilter;
    // relationships
    else if (field.property.isOfType(RelationshipProperty))
      filterType = RelationshipFilter;
    else if (field.property.isOfType(RelationshipPropertyMany))
      filterType = RelationshipManyFilter;

    const newFilterOpts: DynamicFilterOpts = {
      field,
      id: this.dynamicFilters.length,
      ...filterOpts,
    };

    if (this._fieldsById) {
      // REFACTOR
      // find id by field for use as serializedName if relative
      const id = Object.keys(this._fieldsById).find(
        (key) => this._fieldsById && this._fieldsById[key] === field
      );
      if (id && id.indexOf(".") > -1) newFilterOpts.serializedName = id;
    }

    const newFilter = new filterType(newFilterOpts);

    this.dynamicFilters = [...this.dynamicFilters, newFilter];
  }

  public removeFilter(filter: DynamicFilter) {
    this.dynamicFilters = [...this.dynamicFilters.filter((f) => f !== filter)];
  }

  public hasFilter(field: CrudField): boolean {
    return this.dynamicFilters.some(
      (f) => f.serializedName === field.property.serializedName
    );
  }

  public get fieldOptions(): FieldOption[] {
    if (this._fieldsById !== undefined) {
      return Object.keys(this._fieldsById)
        .map((key) => {
          if (!this._fieldsById || this._fieldsById[key] === undefined) return;

          return {
            field: this._fieldsById[key],
            label: this.model.getRelativeFieldLabel(key),
          };
        })
        .filter((f) => f) as FieldOption[];
    }

    return this._fields.map((field) => ({
      field,
      label: field.label,
    }));
  }

  public findFieldOption(serializedName: string): FieldOption | undefined {
    if (this._fieldsById !== undefined) {
      const field = this._fieldsById[serializedName];
      const label = this.model.getRelativeFieldLabel(serializedName);
      if (field && label) {
        return {
          field,
          label,
        };
      }
    }

    return this.fieldOptions.find(
      (fieldOption) =>
        fieldOption.field.property.serializedName === serializedName
    );
  }
}
