import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { ImageField, LongTextField } from "../plugins/Cruxtify/field-types";
import { DateTimeField } from "../plugins/Cruxtify/field-types/DateTimeField";
import { NumberField } from "../plugins/Cruxtify/field-types/NumberField";
import { RelationshipField } from "../plugins/Cruxtify/field-types/RelationshipField";
import { RelationshipFieldMany } from "../plugins/Cruxtify/field-types/RelationshipFieldMany";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";

import {
  DateTimeProperty,
  ImageProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

export class Movie extends CrudModel {
  protected static _typeLabel = "Movie";
  protected static asProperty = "movie";
  public static api = {
    path: "movies",
    fullCache: true,
  };
  protected static routeBase = "movies";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "title",
        rules: ["required"],
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "year",
        label: "Year",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "reason",
        label: "Reason Selected",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "description",
        label: "Movie Description",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "letterboxd_id",
        label: "Letterboxd ID",
      },
    },
    {
      type: ImageProperty,
      opts: {
        name: "poster_s3_id",
        label: "Poster/Teaser",
      },
    },
    {
      type: ImageProperty,
      opts: {
        name: "title_card_s3_id",
        label: "Title Card",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "screenshots",
        label: "Screenshots",
        relatedModel: "Screenshot",
        foreignProperty: "movie",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "movie_night",
        label: "Movie Night",
        relatedModel: "MovieNight",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Selected By",
        relatedModel: "User",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "theme",
        label: "Theme",
        relatedModel: "Theme",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },

    {
      type: StringField,
      opts: {
        property: "title",
        isPrimaryLabelField: true,
      },
    },
    {
      type: NumberField,
      opts: {
        property: "year",
      },
    },
    {
      type: LongTextField,
      opts: {
        property: "reason",
      },
    },
    {
      type: LongTextField,
      opts: {
        property: "description",
      },
    },
    {
      type: StringField,
      opts: {
        property: "letterboxd_id",
      },
    },
    {
      type: ImageField,
      opts: {
        property: "poster_s3_id",
      },
    },
    {
      type: ImageField,
      opts: {
        property: "title_card_s3_id",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "screenshots",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "grid-relationship",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "movie_night",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "user",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "theme",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["movie_night", "title", "theme", "user"],
      enableSearch: true,
      enableDynamicFilters: true,
      useModal: true,
      dynamicFilterOptions: {
        filterFields: [
          "movie_night",
          "title",
          "theme",
          "user",
          "reason",
          "year",
          "created_at",
          "updated_at",
        ],
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      type: "TabbedModelLayout",
      opts: {
        id: "Crudform",
        tabs: [
          {
            id: "overviewTab",
            label: "Details",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                id: "form",
                fieldSets: [
                  {
                    id: "main",
                    fields: ["movie_night", "title", "year", "theme"],
                  },

                  {
                    id: "2",
                    fields: [
                      "poster_s3_id",
                      "title_card_s3_id",
                      "description",
                      "user",
                      "reason",
                    ],
                  },
                ],
              },
            },
          },
          {
            id: "screensTab",
            label: "Screenshots",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fullWidth: true,
                fieldSets: [
                  {
                    id: "screenshots",
                    fields: ["screenshots"],
                  },
                ],
              },
            },
          },
        ],
      },
    },
  ];
}
