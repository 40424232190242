
import { ImageField } from "../../field-types/ImageField";

export default {
  data() {
    return {
      value: null,
      uploadValueSet: false,
      uploadValue: []
    };
  },
  props: {
    field: {
      type: ImageField,
      required: true
    },
    inputProps: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    updateField() {
      this.uploadValueSet = true;
      this.field.set(this.uploadValue);
      this.$emit("change", this.field);
    }
  },
  watch: {
    field: {
      immediate: true,
      handler: function() {
        this.value = this.field.get(true);
        this.uploadValue = [];
      }
    }
  },
  computed: {
    uploadLabel() {
      return (
        (this.value ? "Replace" : "Upload") + " " + this.field.label + "..."
      );
    },
    url() {
      if (this.uploadValueSet) return URL.createObjectURL(this.uploadValue);
      if (this.value) return this.field.property.fileUrl;
      return false;
    }
  }
};
