
import _ from "lodash";
import { CrudCollection } from "../../CrudCollection";
import { RelationshipField } from "../../field-types/RelationshipField";
import { CrudSearchFilter } from "../../filters/CrudSearchFilter";

export default {
  data() {
    return {
      search: null,
      models: [],
      autocompleteItems: [],
      loading: false,
      isUntouched: true,
      value: null,
      searchFilter: CrudSearchFilter.create(),
      updateSearchResults: _.debounce(function() {
        this.searchFilter.setQuery(this.search);
        this.querySelections(this.search);
      }, 250),

      modalOpen: false
    };
  },
  props: {
    field: {
      type: RelationshipField,
      required: true
    },
    inputProps: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    setFieldInitalValue() {
      if (this.field.property.isEmpty) return;

      const propVal = this.field.property.get(true);

      // set default
      this.value = {
        text: this.field.property.getInstanceLabel(),
        value: propVal,
        model: this.field.property.modelInstance
      };

      if (!this.asSelect) {
        this.autocompleteItems = [this.value];
      }
    },
    querySelections(val) {
      if (this.asSelect) return;

      const query = {
        search: val
      };

      this.loading = true;
      this.autocompleteCollection.fetch().then(() => {
        this.isUntouched = false;
        this.loading = false;

        this.autocompleteItems = this.decorateInstances(
          this.autocompleteCollection.instances
        );
      });
    },
    decorateInstances(instances) {
      return instances.map(instance => {
        return {
          text: instance.label,
          value: instance.id,
          model: instance
        };
      });
    },
    maybeClearSearch() {
      return;
      this.search = "";
    },
    clearValue() {
      this.isUntouched = false;
      this.value = null;
    },
    createNew() {
      this.field.createNew().setLabel(this.search);
      this.modalOpen = true;
    },
    cancelNew() {
      this.value = null;
    },
    modalClosed() {
      this.modalOpen = false;
    }
  },
  computed: {
    noDataText: function() {
      return this.isUntouched ? "Type to search" : "No results found.";
    },
    showCreateNew() {
      if (
        !this.field.allowCreateNew ||
        !this.search ||
        this.isUntouched ||
        this.field.isReadonlyToUser()
      )
        return false;

      // make sure the search term is not in the results
      return !this.autocompleteItems.some(
        item =>
          item.text.toLowerCase().trim() == this.search.toLowerCase().trim()
      );
    },
    autocompleteCollection() {
      return new CrudCollection({
        model: this.field.property.relatedModel,
        remoteQueryFilters: [this.searchFilter, ...this.field.filters]
      });
    },
    asSelect() {
      return this.field.property.relatedModel.api.fullCache;
    },
    showValueLink() {
      return this.value && this.value.model && !this.value.model.isNew;
    }
  },
  watch: {
    value: {
      handler: function() {
        if (this.isUntouched) return;

        const curPropertyId = this.field.property.get(true);

        if (!this.value && curPropertyId) {
          this.field.property.set(null);
        } else if (this.value) {
          if (this.value.model && this.value.model.id == curPropertyId) return;

          this.field.property.set(this.value.model);
        }

        this.$emit("change");
      }
    },
    "field.property.serializedChangesValue": {
      // deep: true,
      immediate: true,
      handler() {
        // maybe dont need autocomplete
        if (this.asSelect) {
          this.field.property.relatedModel
            .getAll()
            .then(
              instances =>
                (this.autocompleteItems = this.decorateInstances(instances))
            );
        }

        if (!this.field.property.isEmpty) {
          this.field.property.hydrate().then(() => {
            this.setFieldInitalValue();
          });
        } else {
          this.value = null;
        }
      }
    },
    search() {
      if (!this.search) return;
      this.updateSearchResults();
    }
  }
};
