import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  BooleanField,
  DateTimeField,
  EmailField,
  NumberField,
  RelationshipFieldMany,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  BooleanProperty,
  DateTimeProperty,
  EmailProperty,
  NumberProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class User extends CrudModel {
  protected static _typeLabel = "Freek";
  protected static asProperty = "user";
  public static api = {
    path: "users",
    fullCache: true,
  };

  protected userIdField = ["id"];
  protected static routeBase = "freekz";

  public static modalEdit = true;

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.Read,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_admin",
        label: "Admin Account",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "first_name",
        label: "First Name",
        sortable: true,
        isPrimaryLabelField: true,
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "last_name",
        label: "Last Name",
        sortable: true,
      },
    },
    {
      type: EmailProperty,
      opts: {
        name: "email",
        label: "Email",
        sortable: true,
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "phone",
        label: "Phone",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "letterboxd_id",
        label: "Letterboxd ID",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "discord_id",
        label: "Discord ID",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "password",
        label: "Password (leave blank to ignore)",
        sortable: false,
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "themes",
        label: "Theme Picks",
        relatedModel: "Theme",
        foreignProperty: "user",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "movies",
        label: "Movie Picks",
        relatedModel: "Movie",
        foreignProperty: "user",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "reviews",
        label: "Reviews",
        relatedModel: "Review",
        foreignProperty: "user",
      },
    },
  ];
  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    { type: NumberField, opts: { property: "legacy_id" } },
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: BooleanField, opts: { property: "is_admin" } },
    {
      type: StringField,
      opts: { property: "first_name", isPrimaryLabelField: 1 },
    },
    { type: EmailField, opts: { property: "email" } },
    { type: "PhoneField", opts: { property: "phone" } },
    // { type: StringField, opts: { property: "password" } },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "movies",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "reviews",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["first_name", "email", "phone"],
      useModal: true,
      defaultSort: [
        {
          field: "first",
          order: "asc",
        },
      ],
    },
  ];
}
