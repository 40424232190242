
import { BooleanField } from "../../field-types/BooleanField";

export default {
  props: {
    field: {
      type: BooleanField,
      default: false
    },
    inputProps: {
      type: Object,
      default: () => ({})
    }
  }
};
