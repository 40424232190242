import { CrudModel, CrudModelType } from "~/plugins/Cruxtify/CrudModel";
import { cache } from "./cache";
import { debounce } from "./debounce";

const querystring = require("querystring");
let context;
const commonHeaders = {};
export function setContext(nuxtContext) {
  context = nuxtContext;
  initAxiosConfig();
}

function initAxiosConfig() {
  context.$axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401 || error.response.status === 419) {
        context.$auth.logout();
        context.app.router.push({
          path: "/login",
        });

        // Temp fix for cache issues
        setTimeout(() => {
          location.reload();
        }, 500);
      }

      const errorMessage = error.response.data
        ? error.response.data.message
          ? error.response.data.message
          : "Error"
        : error.status;
      throw new Error(errorMessage);
    }
  );

  commonHeaders[
    "Access-Control-Allow-Origin"
  ] = `${context.$config.apiURL}:${context.$config.apiPort}`;
}

const _apiGet = (
  urlPath = "",
  query = {},
  urlPrefix = "api/",
  additionalParams = {}
) => {
  const args = {
    urlPath,
    query,
    urlPrefix,
    additionalParams,
  };

  // convert periods in query keys to brackets
  Object.keys(query).forEach((key) => {
    if (key.includes(".")) {
      query[key.replace(/\./g, "[") + "]"] = query[key];
      delete query[key];
    }
  });

  return debounce.debounceRequest(args, () => {
    const queryStr = querystring.stringify(query);
    return context.$axios
      .get(`${context.$config.apiURL}/${urlPrefix}${urlPath}/?${queryStr}`, {
        headers: commonHeaders,
        ...additionalParams,
      })
      .then((res) => res.data);
  });
};

const _apiPost = (
  urlPath = "",
  payload: object | FormData = {},
  urlPrefix = "api/",
  additionalAxiosParams: any = {},
  dataRequestOptions: Record<string, any> = {}
) => {
  let contentType = "application/json";
  if (payload instanceof FormData) {
    contentType = "multipart/form-data";
  }

  dataRequestOptions = new URLSearchParams(dataRequestOptions);

  return context.$axios
    .post(
      `${
        context.$config.apiURL
      }/${urlPrefix}${urlPath}/?${dataRequestOptions.toString()}`,
      payload,
      {
        headers: {
          ...commonHeaders,
          "Content-type": contentType,
          ...(additionalAxiosParams.headers
            ? additionalAxiosParams.headers
            : {}),
        },
        ...additionalAxiosParams,
      }
    )
    .then((res) => res.data);
};

const _apiPut = (
  urlPath = "",
  payload: Record<string, any> | FormData = {},
  urlPrefix = "api/",
  dataRequestOptions: Record<string, any> = {}
) => {
  let axiosMethod = "put";
  let contentType = "application/json";

  if (payload instanceof FormData) {
    payload.append("_method", "PUT");
    axiosMethod = "post";
    contentType = "multipart/form-data";
  }

  dataRequestOptions = new URLSearchParams(dataRequestOptions);

  return context.$axios[axiosMethod](
    `${
      context.$config.apiURL
    }/${urlPrefix}${urlPath}/?${dataRequestOptions.toString()}`,
    payload,
    {
      headers: {
        ...commonHeaders,
        "Content-type": contentType,
      },
    }
  ).then((res) => res.data);
};

const _apiDelete = (urlPath = "", urlPrefix = "api/") => {
  return context.$axios
    .delete(`${context.$config.apiURL}/${urlPrefix}${urlPath}/`, {
      headers: commonHeaders,
    })
    .then((res) => res.data);
};

const _buildUrl = (urlPath = "", query = {}) => {
  const queryStr = querystring.stringify(query);
  return `${context.$config.apiURL}/api/${urlPath}?${queryStr}`;
};

export const login = (email: string, password: string) => {
  return context.$axios
    .get(`${context.$config.apiURL}/sanctum/csrf-cookie`, {
      headers: commonHeaders,
    })
    .then(() => {
      return context.$axios.post(
        `${context.$config.apiURL}/login`,
        {
          email,
          password,
        },
        { headers: commonHeaders }
      );
    });
};

const _buildModelRequestParams = (opts: ApiModelRequestOptions = {}) => {
  const params = {};

  if (typeof opts.onlyProperties !== "undefined") {
    params["_properties"] = opts.onlyProperties.join(",");
  }

  if (typeof opts.populate !== "undefined") {
    params["_populate"] = opts.populate.join(",");
  }

  return params;
};

type ApiModelRequestOptions = {
  onlyProperties?: string[];
  populate?: string[];
};

export type ApiCreateOptions = ApiModelRequestOptions;

export type ApiUpdateOptions = ApiModelRequestOptions;

export type ApiGetOptions = ApiModelRequestOptions;

export type ApiGetOneOptions = ApiGetOptions & ApiCachedOptions;

export type ApiCachedOptions = {
  noCache?: boolean;
};

export const api = {
  GET: _apiGet,
  POST: _apiPost,
  PUT: _apiPut,
  DELETE: _apiDelete,

  // crudmodel
  get: (modelType: CrudModelType, query = {}, opts: ApiGetOptions = {}) => {
    const path = modelType.api.path;

    return _apiGet(`${path}`, { ...query, ..._buildModelRequestParams(opts) });
  },
  getAll: (
    modelType: CrudModelType,
    query = {},
    opts: ApiGetOptions & ApiCachedOptions = {}
  ) => {
    if (cache.modelIsFullCached(modelType) && !opts.noCache)
      return cache.interceptGetAll(modelType);

    const path = modelType.api.path;

    const params = {
      _perPage: -1,
      ..._buildModelRequestParams(opts),
    };

    return _apiGet(`${path}`, params).then((res) => {
      return res.data.map((data) => new modelType(data));
    });
  },
  getOne: (
    modelType: CrudModelType,
    id: number,
    opts: ApiGetOneOptions = {}
  ) => {
    if (cache.modelIsFullCached(modelType, id) && !opts.noCache)
      return cache.interceptGetOne(modelType, id);

    const path = modelType.api.path;

    return _apiGet(`${path}/${id}`, _buildModelRequestParams(opts));
  },
  create: (model: CrudModel, opts: ApiCreateOptions = {}) => {
    const path = model.api.path;

    return _apiPost(
      `${path}`,
      model.toApiPayload(),
      "api/",
      {},
      _buildModelRequestParams(opts)
    ).then((res) => {
      // if (cache.modelIsCached(model)) {
      // TODO: refactor this once the hydration happens on the API instead of model
      const createdModel = new (model.getModelType())(res.data);
      cache.updateCachedModel(createdModel);
      // }
      return res;
    });
  },
  update: (model: CrudModel, opts: ApiUpdateOptions = {}) => {
    const path = model.api.path;
    if (model.isNew) return api.create(model, opts);

    const id = model.id;

    return _apiPut(
      `${path}/${id}`,
      model.toApiPayload(),
      "api/",
      _buildModelRequestParams(opts)
    ).then((res) => {
      // if (cache.modelIsCached(model)) {
      // TODO: refactor this once the hydration happens on the API instead of model
      const updatedModel = new (model.getModelType())(res.data);
      cache.updateCachedModel(updatedModel);
      // }
      return res;
    });
  },
  delete: (model: CrudModel) => {
    const path = model.api.path;
    const id = model.id;

    return _apiDelete(`${path}/${id}`);
  },

  export: (modelType: CrudModelType, query = {}) => {
    const path = modelType.api.path;

    return _apiGet(`${path}/export`, query, "api/", {
      responseType: "blob",
      headers: {
        Accept: "application/vnd.ms-excel",
      },
    });
  },

  // auth/user
  me: () => _apiGet("user"),
  registerUser: (userData: Record<string, any>) =>
    _apiPost("register", userData, ""),
  passwordForgot: (email: string) => _apiPost("forgot-password", { email }, ""),
  passwordReset: (email: string, password: string, token: string) =>
    _apiPost(
      "reset-password",
      {
        token,
        email,
        password,
        password_confirmation: password,
      },
      ""
    ),

  // stripe
  getStripeIntent: () => _apiGet("stripeIntent"),

  // misc
  buildUrl: _buildUrl,

  // cache
  cache,
};
