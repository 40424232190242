
import _ from "lodash";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      items: [],
      page: 1,
      collectionQueryOptsMap: {
        sortBy: "sortBy",
        sortDesc: "sortDesc",
        page: "page",
        itemsPerPage: "perPage",
      },
      isSortMode: this.layout.ordinalField && !this.layout.expansionEnabled,
      saveOnEdit: false,
      editedIndex: -1,
      editedItem: {},
      expanded: [],
      dense: this.layout.dense,
      query: null,
      tableRenderKey: 0,
      bubbleTableRowEvents: {
        "edit-item": (item) => this.$emit("edit-item", item),
        "delete-item": (item) => this.$emit("delete-item", item),
        "restore-item": (item) => this.$emit("restore-item", item),
      },

      page: 1,
      zoomedInstance: null,
      showDialog: false,
      zoomCopiedImage: false,
      zoomCopiedUrl: false,
    };
  },

  props: {
    layout: {
      type: Object,
      required: true,
    },
    showModelActions: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateRowItems() {
      this.items = this.layout.collection.instances.map((instance) => {
        return this.buildGridItem(instance);
      });

      this.tableRenderKey++;
    },

    zoomImage(item) {
      this.zoomedInstance = item;
      this.showDialog = true;
    },

    async copyImageToClipboard(full) {
      full = false;
      try {
        const response = await fetch(
          full ? this.zoomedInstance.image : this.zoomedInstance.thumb
        );
        const blob = await response.blob();

        const dataUrl = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });

        const img = new Image();
        img.src = dataUrl;
        await new Promise((resolve) => (img.onload = resolve));

        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const context = canvas.getContext("2d");
        context.drawImage(img, 0, 0);

        const pngBlob = await new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (blob) resolve(blob);
            else reject(new Error("Failed to convert to PNG"));
          }, "image/png");
        });

        await navigator.clipboard.write([
          new ClipboardItem({ "image/png": pngBlob }),
        ]);

        this.zoomCopiedImage = true;
      } catch (error) {
        console.error("Failed to copy image:", error);
      }
    },

    async copyUrlToClipboard() {
      try {
        await navigator.clipboard.writeText(this.zoomedInstance.image);
        this.zoomCopiedUrl = true;
      } catch (error) {
        console.error("Failed to copy URL:", error);
      }
    },

    onModalSaved() {
      // TODO: REFACTOR
      // this.redrawItemFromModel(this.singleModel);
      this.tableRenderKey++;
    },

    buildGridItem(instance) {
      let itemData = { id: instance.id, instance, vueKey: 0 };

      itemData.title = this.layout.titleProperty
        ? instance.getProperty(this.layout.titleProperty).stringValue
        : instance.label;

      itemData.subTitle = this.layout.subTitleProperty
        ? instance.getProperty(this.layout.subTitleProperty).stringValue
        : null;

      itemData.image = this.layout.imageProperty
        ? instance.getProperty(this.layout.imageProperty).fileUrl
        : null;

      itemData.thumb = this.layout.thumbnailProperty
        ? instance.getProperty(this.layout.thumbnailProperty).fileUrl
        : itemData.image;

      return itemData;
    },

    getRowIndex(itemData) {
      return this.items.findIndex((item) => item.id == itemData.id);
    },

    forceTableRedraw() {
      this.tableRenderKey++;
    },

    redrawItemFromModel(model) {
      const itemData = this.buildGridItem(model);
      this.updateRow(itemData);
    },

    updateRow(itemData, updatedProperties = {}) {
      const newitemData = Object.assign(
        itemData,
        {
          vueKey: Date.now(),
        },
        updatedProperties
      );

      const itemIndex = this.getRowIndex(itemData);

      if (itemIndex == -1) this.items.unshift(newitemData);
      else this.items.splice(itemIndex, 1, newitemData);

      this.forceTableRedraw();
    },

    updateOrder(e) {
      if (e.oldIndex === e.newIndex) return;

      // update everything between oldIndex and newIndex
      const lowIndex = Math.min(e.oldIndex, e.newIndex);
      const highIndex = Math.max(e.oldIndex, e.newIndex);

      this.items.forEach((item, ind) => {
        if (ind < lowIndex || ind > highIndex) return;

        item.instance.setField(this.layout.ordinalField, ind);
      });
    },
  },
  watch: {
    page(val) {
      this.layout.collection.setQueryOption("page", val);

      this.$vuetify.goTo(this.$refs.layout);
    },
    "layout.collection.remoteQueryOptions": {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        // key: layout.collection query option
        // value: datatable options

        const changedProps = !oldVal
          ? Object.keys(newVal)
          : Object.keys(newVal).filter(
              (key) => !_.isEqual(newVal[key], oldVal[key])
            );

        if (changedProps.includes("page")) {
          this.page = newVal.page;
        }
      },
    },
    loadedValuesProxy: {
      immediate: true,
      handler(val) {
        this.updateRowItems();
      },
    },
    zoomedInstance(val) {
      this.zoomCopiedImage = false;
      this.zoomCopiedUrl = false;
    },
  },
  mounted() {
    this.layout.collection.fetchIfUnhydrated();
  },
  computed: {
    groupByEnabled() {
      return (
        !!this.layout.groupBy && !(this.isSortMode && this.layout.ordinalField)
      );
    },
    columns() {
      const fieldsByRelativeId =
        this.layout.collection.model.findFieldsByRelativeId(
          this.layout.columnFields
        );

      const columns = Object.keys(fieldsByRelativeId)
        // hide static field filters
        .filter((fieldId) => {
          if (
            !this.layout.collection.staticFilters ||
            this.layout.showStaticFilterColumns
          )
            return true;

          return !this.layout.collection.staticFilters.find(
            (filter) => filter.queryName == fieldId
          );
        })
        // hide hidden fields
        .filter((fieldId) => {
          const field = fieldsByRelativeId[fieldId];
          return field.isVisibleToUser(null, false);
        })
        .map((fieldId) => {
          const field = fieldsByRelativeId[fieldId];
          const isModelOwnField = fieldId.indexOf(".") === -1;

          return {
            text: field.label,
            value: fieldId,
            sortable: this.layout.ordinalField
              ? false
              : field.property.sortable,
            itemSlot: "item." + fieldId,
            isPrimaryLabelField: field.isPrimaryLabelField && isModelOwnField,
            field,
          };
        });

      // add left padding for groupby
      if (this.groupByEnabled)
        columns.unshift({
          text: "",
          value: "_group-padding",
          sortable: false,
          isEmpty: true,
        });

      if (
        // built-in actions
        this.layout.enabledItemActions.length > 0 ||
        this.layout.itemActionComponents.length > 0 ||
        // slot actions
        !!this.$slots["item-actions"] ||
        !!this.$scopedSlots["item-actions"]
      )
        columns.push({ text: "", value: "_item-actions", sortable: false });

      if (
        // slot actions
        this.layout.expansionEnabled ||
        this.layout.ordinalField
      )
        columns.unshift({ text: "", value: "_table-action", sortable: false });

      return columns;
    },
    showSortToggle() {
      return this.layout.expansionEnabled && this.layout.ordinalField;
    },
    // taken from https://stackoverflow.com/questions/42737034/vue-js-watch-multiple-properties-with-single-handler#answer-45853349
    loadedValuesProxy() {
      return (
        "" +
        this.layout.collection.newInstances.length +
        "|" +
        this.layout.collection.instances.length +
        "|" +
        this.layout.collection.ids.join("|")
      );
    },
  },
};
