
import { DynamicFilterController } from "../../filters/DynamicFilterController";

export default {
  data() {
    return {
      newFieldSearchValue: "",
      newFieldValue: null,
      newFieldOpen: false,
    };
  },
  props: {
    controller: {
      type: DynamicFilterController,
      required: true,
    },
  },
  methods: {
    removeFilter(filter) {
      this.controller.removeFilter(filter);
    },
    consoleLog() {
      console.log(this.controller.filterValues, this.controller);
    },
    selectNewField(fieldId) {
      const fieldOption = this.fieldOptions.find(
        (fieldOption) => fieldOption.field.id === fieldId
      );
      if (!fieldOption) return console.error("Field not found: " + fieldId);

      this.controller.addFilter(fieldOption.field);

      this.newFieldOpen = false;
      this.$nextTick(() => {
        this.newFieldSearchValue = "";
        this.newFieldValue = null;
      });
    },
    focusFieldSelect() {
      setTimeout(() => {
        this.$refs["field-select"].focus();
      }, 100);
    },
  },
  computed: {
    fieldOptions() {
      const fieldsByLabel = this.controller.fieldOptions;

      // sort by label
      fieldsByLabel.sort((a, b) => (a.label < b.label ? -1 : 1));

      return fieldsByLabel;
    },
    selectOptions() {
      return this.fieldOptions.map((fieldOption) => ({
        text: fieldOption.label,
        value: fieldOption.field.id,
      }));
    },
  },
};
