
import { CrudField } from "../../CrudField";

export default {
  props: {
    field: {
      type: CrudField,
      required: true
    },
    inputProps: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    valueIsNone() {
      return this.field.isEmpty;
    },
    textLines() {
      if (!this.field.stringValue) return [];
      return this.field.stringValue.split
        ? this.field.stringValue.split("\n")
        : [this.field.stringValue];
    },
    noneText() {
      return this.$config.readonlyNoneDisplay;
    }
  }
};
