
import { CheckboxField } from "../../field-types/CheckboxField";

export default {
  props: {
    field: {
      type: CheckboxField,
      required: true
    },
    inputProps: {
      type: Object,
      default: () => ({})
    }
  }
};
