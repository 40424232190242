
import { DynamicFilter } from "../../filters/DynamicFilter";

export default {
  data() {
    return {
      open: true,
      uiField: this.filter.getUiField(),
      updateValueDebounced: _.debounce(() => {
        this.filter.property.set(this.uiField.property.typedValue);
      }, 100),
      renderKey: 0,
    };
  },
  props: {
    filter: {
      type: DynamicFilter,
      required: true,
    },
  },
  methods: {
    selectOperator(operator) {
      this.filter.setOperator(operator);
    },
  },
  mounted() {
    if (this.filter.value) {
      this.open = false;
    }

    this.filter.getValueAsync();
  },
  computed: {
    operatorChoices() {
      return this.filter.operators.map((operator) => ({
        text: operator.label,
        value: operator.key,
      }));
    },
  },
  watch: {
    open(val) {
      if (!val && typeof this.filter.value !== "undefined")
        setTimeout(() => (this.showOperatorSelect = true), 250);
    },
    "uiField.property.value": {
      handler() {
        this.updateValueDebounced();
      },
    },
  },
};
