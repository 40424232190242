import { CrudModel } from "../CrudModel";
import { ChoiceField, IChoiceField } from "./ChoiceField";

export interface ICheckboxField extends IChoiceField {
  checkboxSize?: string;
}
export class CheckboxField extends ChoiceField {
  public static fieldName = "CheckboxField";
  public static formComponentName: string = "form-checkbox-field";
  protected static requiresDetachedLabel: boolean = true;

  public checkboxSize = "normal";

  constructor(opts: ICheckboxField, model: CrudModel) {
    super(opts, model);

    if (typeof opts.multiple === "undefined") this.multiple = true;

    if (typeof opts.checkboxSize !== "undefined")
      this.checkboxSize = opts.checkboxSize;
  }
}
