import { CrudLayout } from "../../CrudLayout";
import {
  TabbedLayoutTabDefinition,
  TabbedLayoutDefinition,
  TabbedLayoutOpts,
  TabbedLayoutTab,
  TabbedLayout,
} from "../../mix-ins/TabbedLayout";
import {
  ModelLayout,
  ModelLayoutDefinition,
  ModelLayoutOpts,
} from "../ModelLayout";

export interface TabbedModelLayoutTab extends TabbedLayoutTabDefinition {
  contentLayout: ModelLayout;
}

export interface TabbedModelLayoutDefinition
  extends TabbedLayoutDefinition,
    ModelLayoutDefinition {}

export type TabbedModelLayoutOpts = TabbedLayoutOpts &
  TabbedModelLayoutDefinition &
  ModelLayoutOpts;

export class TabbedModelLayout extends ModelLayout implements TabbedLayout {
  public static componentName = "tabbed-model-layout";

  protected tabsDefinition: TabbedLayoutTabDefinition[] = [];
  protected _tabs: TabbedModelLayoutTab[] | undefined;
  public get tabs() {
    if (typeof this._tabs === "undefined") {
      this._tabs = this.tabsDefinition.map(
        (tabDef) =>
          ({
            ...tabDef,
            contentLayout: CrudLayout.newInstance(tabDef.content, {
              model: this.model,
            }),
          } as TabbedModelLayoutTab)
      );
    }

    return this._tabs;
  }

  constructor(opts: TabbedModelLayoutOpts) {
    super(opts);

    if (typeof opts.tabs !== "undefined") this.tabsDefinition = opts.tabs;
  }
}
