
import _ from "lodash";
import { LongTextField } from "../../field-types/LongTextField";

export default {
  props: {
    field: {
      type: LongTextField,
      required: true
    },
    inputProps: {
      type: Object,
      default: () => ({})
    }
  }
};
