import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { ImageField } from "../plugins/Cruxtify/field-types";
import { DateTimeField } from "../plugins/Cruxtify/field-types/DateTimeField";
import { NumberField } from "../plugins/Cruxtify/field-types/NumberField";
import { RelationshipField } from "../plugins/Cruxtify/field-types/RelationshipField";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";

import {
  DateTimeProperty,
  ImageProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

export class Screenshot extends CrudModel {
  protected static _typeLabel = "Screenshot";
  protected static asProperty = "screenshot";
  public static api = {
    path: "screenshots",
  };
  protected static routeBase = "screenshots";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: ImageProperty,
      opts: {
        name: "s3_id",
        label: "Image",
        rules: ["required"],
        computed: true,
      },
    },
    {
      type: ImageProperty,
      opts: {
        name: "thumbnail_s3_id",
        label: "Preview Image",
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "ocr",
        label: "Text",
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "sort_order",
        label: "Sort Order",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Author",
        relatedModel: "User",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "movie",
        label: "Movie",
        relatedModel: "Movie",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },

    {
      type: ImageField,
      opts: {
        property: "s3_id",
      },
    },
    {
      type: ImageField,
      opts: {
        property: "thumbnail_s3_id",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "sort_order",
      },
    },
    {
      type: StringField,
      opts: {
        property: "ocr",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "movie",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "user",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      type: "ImageGridLayout",
      opts: {
        id: "grid",
        noNew: true,
        noRefresh: true,
        imageProperty: "s3_id",
        thumbnailProperty: "thumbnail_s3_id",
        titleProperty: "movie",
        enableSearch: true,
        showInfoOnHover: true,
        filterFields: ["movie"],
        defaultSort: [
          {
            field: "created_at",
            order: "desc",
          },
        ],
      },
    },
    {
      type: "ImageGridLayout",
      opts: {
        id: "grid-relationship",
        noNew: true,
        noRefresh: true,
        imageProperty: "s3_id",
        thumbnailProperty: "thumbnail_s3_id",
        titleProperty: "movie",
        enableSearch: false,
        showInfoOnHover: true,
        filterFields: ["movie"],
        defaultSort: [
          {
            field: "created_at",
            order: "asc",
          },
        ],
      },
    },
    {
      id: "table",
      columnFields: ["thumbnail_s3_id", "movie", "user"],
      enableSearch: true,
      enableDynamicFilters: true,
      filterFields: ["movie"],
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "form",
      fieldGroups: [
        {
          id: "main",
          label: "Main",
          fields: ["s3_id", "ocr", "movie", "user"],
        },
      ],
    },
  ];
}
