
export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    inputProps: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    uploadLabel() {
      return !this.field.isEmpty ? "Replace file..." : "Select file...";
    },
    url() {
      if (this.field.value) return this.field.property.fileUrl;
      return false;
    }
  }
};
