import { CrudModel } from "../CrudModel";
import { CrudProperty, ICrudProperty } from "../CrudProperty";

export class ArrayStringProperty extends CrudProperty {
  protected val: string[] = [];

  constructor(opts: ICrudProperty, model: CrudModel) {
    super(opts, model);

    // defaults
    if (typeof opts.sortable === "undefined") this.sortable = true;
  }
}
