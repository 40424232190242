import { StringField } from "../field-types";
import { CrudFieldFilter, ICrudFieldFilter } from "./CrudFieldFilter";

export class CrudSearchFilter extends CrudFieldFilter {
  public get componentName() {
    return super.componentName ? super.componentName : "form-text-field";
  }

  public static create(searchLabel?: string, filterOpts?: ICrudFieldFilter) {
    const field = new StringField({
      id: "search",
      label: searchLabel ? searchLabel : "Search",
      icon: "mdi-magnify"
    });

    const defaultFilterOpts = {
      queryName: "search",
      name: "search",
      field
    };

    if (typeof filterOpts === "undefined") filterOpts = defaultFilterOpts;
    else {
      if (typeof filterOpts.queryName === "undefined")
        filterOpts.queryName = defaultFilterOpts.queryName;

      if (typeof filterOpts.name === "undefined")
        filterOpts.name = defaultFilterOpts.name;
    }

    return new CrudSearchFilter(filterOpts);
  }
}
