
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      clipped: false,
      drawer: true,
      fixed: false,
      accountLoading: false,
      accountMenuItems: [
        {
          icon: "mdi-account-circle",
          title: "My Account",
          to: "/my-account",
          // to: "/users/{current_user}"
        },
        {
          icon: "mdi-exit-to-app",
          title: "Logout",
          click: function () {
            this.accountLoading = true;
            this.$auth.logout().then(() => {
              this.accountLoading = false;
              this.$router.push({
                path: "/login",
              });
            });
          }.bind(this),
        },
      ],
      allPrimaryMenuItems: [
        {
          icon: "mdi-theater",
          title: "Movie Nights",
          to: "/movie-nights",
          adminOnly: true,
        },
        {
          icon: "mdi-movie-roll",
          title: "Movies",
          to: "/movies",
        },
        {
          icon: "mdi-shape",
          title: "Themes",
          to: "/themes",
          adminOnly: true,
        },
        {
          icon: "mdi-image-area",
          title: "Screenshots",
          to: "/screenshots",
        },
        {
          icon: "mdi-account-group",
          title: "Freekz",
          to: "/freekz",
          adminOnly: true,
        },
      ],
      miniVariant: false,
    };
  },
  mounted() {
    if (typeof this.$route.query.debug !== "undefined") {
      this.$config.debug = this.$route.query.debug == "1" ? true : false;
    }
  },
  computed: {
    ...mapGetters("app", ["title"]),
    activePrimaryMenuItems() {
      return this.allPrimaryMenuItems.filter(
        (item) => !item.adminOnly || this.$auth.user.is_admin
      );
    },
    activeAccountMenuItems() {
      return this.accountMenuItems.filter(
        (item) => !item.adminOnly || this.$auth.user.is_admin
      );
    },
    userName() {
      if (!this.$auth.loggedIn) return "";
      return this.$auth.user.first_name;
    },
  },
  methods: {
    decorateTo(to) {
      if (to && to.indexOf("{current_user}") !== -1) {
        to = to.replace("{current_user}", this.$auth.user.id);
      }
      return to;
    },
  },
};
