
export default {
  data() {
    return {
      isExpanded: false
    };
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      this.$emit("expanded", this.isExpanded);
    }
  }
};
