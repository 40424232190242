import {
  DynamicFilter,
  DynamicFilterOperator,
  DynamicFilterOpts,
} from "../DynamicFilter";

export class PhoneFilter extends DynamicFilter {
  public defaultOperator: DynamicFilterOperator = "contains";
  public disabledOperators: DynamicFilterOperator[] = [
    "greaterThan",
    "lessThan",
  ];

  constructor(opts: DynamicFilterOpts) {
    super(opts);

    if (typeof opts.operator !== "undefined") {
      this.operator = opts.operator;
    } else {
      this.operator = this.defaultOperator;
    }
  }
}
