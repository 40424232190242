import { CrudModel } from "../CrudModel";
import { IStringField, StringField } from "./StringField";

export class UrlField extends StringField {
  public static fieldName = "UrlField";
  public static formComponentName: string = "form-string-field";
  public static icon = "mdi-link";

  constructor(opts: IStringField, model: CrudModel) {
    super(opts, model);

    // defaults
    if (typeof opts.cols === "undefined") this.cols = 6;
  }
}
