import { Conditional, HasConditional, IConditional } from "../Conditional";

export enum ConditionalOperator {
  AND = "AND",
  OR = "OR"
}

export interface IGroupedConditional extends IConditional {
  operator?: ConditionalOperator;
  conditionals?: IConditional[];
}

export class GroupedConditional extends Conditional {
  protected conditionals: Conditional[] = [];

  protected operator: ConditionalOperator = ConditionalOperator.AND;

  constructor(opts: IGroupedConditional, owner: HasConditional) {
    super(opts, owner);

    if (typeof opts.operator !== "undefined") this.operator = opts.operator;

    if (typeof opts.conditionals !== "undefined")
      this.conditionals = opts.conditionals.map(
        (conditional: IConditional, i) =>
          Conditional.newInstance(conditional, this.owner)
      );
  }

  public get conditionalValues(): boolean[] {
    return this.conditionals.map((c: Conditional) => c.result);
  }

  public get result(): boolean {
    switch (this.operator) {
      case ConditionalOperator.AND:
        return this.conditionalValues.every((v: boolean) => v);
      case ConditionalOperator.OR:
        return this.conditionalValues.some((v: boolean) => v);
    }
  }
}
