import { CrudField, ICrudField } from "../CrudField";
import { CrudModel } from "../CrudModel";

export class ComputedField extends CrudField {
  public static fieldName = "ComputedField";

  protected _model: CrudModel;
  public get model(): CrudModel | undefined {
    return this._model;
  }

  constructor(opts: ICrudField, model: CrudModel) {
    super(opts, model);

    this._model = model;
  }
}
