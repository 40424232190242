
import { FieldSetGridLayout } from "../../../layouts";

export default {
  props: {
    layout: {
      type: FieldSetGridLayout,
      required: true,
    },
    readMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    fieldSets() {
      return this.layout.fieldSets;
    },
  },
  watch: {
    "layout.model": {
      immediate: true,
      handler() {
        this.formReadonly =
          this.readMode || this.layout.model.isReadonlyToUser();
      },
    },
  },
};
