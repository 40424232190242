import { CrudModel, CrudModelType } from "../CrudModel";

interface CachedModel {
  loaded: boolean;
  loadingPromise: boolean;
  instancesById: Record<string, CrudModel>;
}

export class cache {
  protected static fullCacheModels: Record<string, CachedModel> = {};
  protected static cacheModels: Record<string, Record<string, CrudModel>> = {};

  public static registerFullCacheModel(model: CrudModel | CrudModelType) {
    this.fullCacheModels[model.typeLabel] = {
      loaded: false,
      loadingPromise: false,
      instancesById: {},
    };
  }

  public static registerCacheModel(model: CrudModel) {
    if (!this.cacheModels[model.typeLabel]) {
      this.cacheModels[model.typeLabel] = {};
    }

    this.cacheModels[model.typeLabel][model.id] = model;
  }

  public static getFromCache(
    modelType: CrudModelType,
    id: string | number,
    data?: object
  ) {
    if (!this.cacheModels[modelType.typeLabel]) {
      this.cacheModels[modelType.typeLabel] = {};
    }

    if (typeof id === "number") id = id.toString();

    if (!this.cacheModels[modelType.typeLabel][id]) {
      if (!data) return new modelType({ id });

      this.cacheModels[modelType.typeLabel][id] = new modelType(data);
    }

    return this.cacheModels[modelType.typeLabel][id];
  }

  protected static async loadFullCacheModel(modelType: CrudModelType) {
    if (!this.fullCacheModels[modelType.typeLabel]) {
      this.registerFullCacheModel(modelType);
    }

    if (this.fullCacheModels[modelType.typeLabel].loaded) return;

    if (!this.fullCacheModels[modelType.typeLabel].loadingPromise) {
      this.fullCacheModels[modelType.typeLabel].loadingPromise = modelType
        .get({ _perPage: -1, _autoPopulate: 0 })
        .then((data) => {
          this.fullCacheModels[modelType.typeLabel].instancesById =
            data.dataHydrated.reduce(
              (acc: Record<string, CrudModel>, model: CrudModel) => {
                acc[model.id] = model;
                return acc;
              },
              {}
            );
          this.fullCacheModels[modelType.typeLabel].loaded = true;
        })
        .finally(() => {
          this.fullCacheModels[modelType.typeLabel].loadingPromise = false;
        });
    }

    return this.fullCacheModels[modelType.typeLabel].loadingPromise;
  }

  public static getCachedStore(model: CrudModel | CrudModelType) {
    if (model.api.fullCache) {
      if (!this.fullCacheModels[model.typeLabel]) {
        this.registerFullCacheModel(model);
      }

      return "fullCacheModels";
    }

    return "cacheModels";
  }

  public static modelIsFullCached(
    modelType: CrudModelType,
    id?: number | string
  ) {
    const store = this.getCachedStore(modelType);

    return store === "fullCacheModels";
  }

  public static updateCachedModel(model: CrudModel) {
    const store = this.getCachedStore(model);

    if (store == "cacheModels" && !this[store][model.typeLabel]) {
      this[store][model.typeLabel] = {};
    }

    // unless it has unsaved changes
    if (
      !this[store][model.typeLabel][model.id] ||
      !this[store][model.typeLabel][model.id].hasUnsavedChanges
    ) {
      this[store][model.typeLabel][model.id] = model;
    }
  }

  public static async interceptGetOne(modelType: CrudModelType, id: number) {
    await this.loadFullCacheModel(modelType);

    return this.fullCacheModels[modelType.typeLabel].instancesById[id];
  }

  public static async interceptGetAll(modelType: CrudModelType) {
    await this.loadFullCacheModel(modelType);

    return Object.values(
      this.fullCacheModels[modelType.typeLabel].instancesById
    );
  }
}

// @ts-ignore
if (typeof window !== "undefined") {
  // @ts-ignore
  window.Cruxtify = window.Cruxtify || {};
  // @ts-ignore
  window.Cruxtify.cache = cache;
}
