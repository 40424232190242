import { CrudField } from "../CrudField";
import { CrudFilter, ICrudFilter } from "../CrudFilter";
import { UserPermission } from "../UserPermission";

export interface ICrudFieldFilter extends ICrudFilter {
  field: CrudField;
}

export class CrudFieldFilter extends CrudFilter {
  public field: CrudField;

  public get componentName() {
    return super.componentName ? super.componentName : "field";
  }

  public get componentOpts() {
    return Object.assign({}, super.componentOpts, {
      field: this.field,
      readMode: false,
      "input-only": true,
      "keep-input-label": true,
    });
  }

  constructor(opts: ICrudFieldFilter) {
    super(opts);

    this.field = opts.field;

    if (typeof opts.queryName === "undefined") {
      if (this.field.property)
        this._queryName = this.field.property.serializedChangesName;
      else
        throw Error(
          "CrudFieldFilter: queryName must be defined if field does not have a property"
        );
    }
  }

  public get query(): object {
    return { [this.queryName]: this.serializedValue };
  }

  public get serializedValue(): string {
    return this.field.property.serializedValue;
  }

  public setQuery(value: any) {
    super.setQuery(value);

    // this filter is tied to a field, so we need to set the field's value
    this.field.set(value);
  }

  public clear() {
    return this.field.property.clear();
  }

  public isSet() {
    return !this.field.isEmpty;
  }

  public get isVisible() {
    return super.isVisible && this.field.isVisibleToUser();
  }

  public asDefaultProperty(value?: any) {
    return this.field.property;
  }

  public static fromField(
    field: CrudField,
    additionalOptions = {}
  ): CrudFieldFilter {
    return new CrudFieldFilter({
      name: field.property.name,
      field: field.clone({
        userPermissions: UserPermission.Edit,
        disableRules: true,
      }),
      ...additionalOptions,
    });
  }
}
