import { MoneyProperty } from "../property-types/MoneyProperty";
import { NumberField } from "./NumberField";

export class MoneyField extends NumberField {
  public static fieldName = "MoneyField";
  public static formComponentName: string = "form-money-field";

  protected currencySymbol = "$";

  protected static readonly defaultPropertyType = MoneyProperty;
}
