import { ImageProperty } from "../property-types/ImageProperty";
import { FileField } from "./FileField";

export class ImageField extends FileField {
  public static fieldName = "ImageField";

  public static formComponentName: string = "form-image-field";
  public static viewComponentName: string = "view-image-field";

  protected static readonly defaultPropertyType = ImageProperty;

  public static requiresDetachedLabel = true;
}
