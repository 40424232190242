import { Conditional, HasConditional, IConditional } from "../Conditional";

export type ConditionalComparer = (
  propertyValue: any,
  compareValue: any
) => boolean;
export type IConditionalComparer = ConditionalComparer | string;

export interface ICompareConditional extends IConditional {
  value?: string | number | boolean;
  compare?: IConditionalComparer;
}

export interface HasCompareConditional extends HasConditional {}

export class CompareConditional extends Conditional {
  protected compareValue: string | number | boolean = true;
  protected comparerDef: IConditionalComparer = "equals";

  private static _comparers: Record<string, IConditionalComparer> = {};
  public static getComparer(
    comparer: string | ConditionalComparer
  ): ConditionalComparer {
    if (typeof comparer !== "string") return comparer as ConditionalComparer;

    if (!this._comparers[comparer]) {
      console.error(this._comparers);
      throw new Error("Comparer not registered: " + comparer);
    }

    return this._comparers[comparer] as ConditionalComparer;
  }
  public static registerComparer(name: string, comparer: IConditionalComparer) {
    if (this._comparers[name]) {
      console.error(this._comparers[name]);
      throw new Error("Comparer name already exists: " + name);
    }

    this._comparers[name] = comparer;
  }
  public static registerComparers(
    registrationMap: Record<string, IConditionalComparer> = {}
  ) {
    Object.keys(registrationMap).forEach((name: string) =>
      this.registerComparer(name, registrationMap[name])
    );
  }

  protected _comparer: ConditionalComparer | undefined;
  protected get comparer() {
    if (typeof this._comparer === "undefined") {
      this._comparer = CompareConditional.getComparer(this.comparerDef);
    }
    return this._comparer;
  }

  constructor(opts: ICompareConditional, owner: HasCompareConditional) {
    super(opts, owner);

    if (typeof opts.value !== "undefined") this.compareValue = opts.value;

    if (typeof opts.compare !== "undefined") this.comparerDef = opts.compare;
  }

  protected get compare() {
    return true;
  }

  public get result(): boolean {
    return this.comparer(this.compare, this.compareValue);
  }
}
