import { CollectionLayout } from ".";
import { CrudLayout } from "../CrudLayout";
import { CrudModel, ICrudModelType } from "../CrudModel";

import {
  TabbedLayout,
  TabbedLayoutTabDefinition,
  TabbedLayoutDefinition,
  TabbedLayoutOpts,
  TabbedLayoutTab,
} from "../mix-ins/TabbedLayout";
import {
  CollectionLayoutDefinition,
  CollectionLayoutOpts,
} from "./CollectionLayout";

export interface CollectionLayoutTabDefinition
  extends TabbedLayoutTabDefinition {
  modelType: ICrudModelType;
}
export interface CollectionLayoutTab
  extends CollectionLayoutTabDefinition,
    TabbedLayoutTab {
  contentLayout: CollectionLayout;
}

export interface TabbedCollectionsLayoutDefinition
  extends TabbedLayoutDefinition,
    CollectionLayoutDefinition {
  tabs: CollectionLayoutTab[];
}

export interface TabbedCollectionsLayoutOpts
  extends TabbedLayoutOpts,
    TabbedCollectionsLayoutDefinition,
    CollectionLayoutOpts {
  tabs: CollectionLayoutTab[];
}

export class TabbedCollectionsLayout
  extends CrudLayout
  implements TabbedLayout
{
  public static componentName = "tabbed-collections-layout";

  protected tabsDefinition: CollectionLayoutTab[] = [];
  protected _tabs: CollectionLayoutTab[] = [];
  public get tabs() {
    return this._tabs;
  }

  constructor(opts: TabbedCollectionsLayoutOpts) {
    super(opts);

    if (typeof opts.tabs !== "undefined") this.tabsDefinition = opts.tabs;

    this._tabs = this.tabsDefinition.map(
      (tabDef) =>
        ({
          ...tabDef,
          contentLayout: CrudLayout.newInstance(tabDef.content, {
            modelType: CrudModel.getModelType(tabDef.modelType),
          }),
        } as CollectionLayoutTab)
    );
  }
}
