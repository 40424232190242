
import { CrudModel } from "../../../CrudModel";
import _ from "lodash";

export default {
  props: {
    model: {
      type: CrudModel,
      required: true,
    },
    layoutId: {
      type: String,
      default: "",
    },
    hideReadonly: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    readMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isValid: true,
      hasUnsavedChanges: true,
      saving: false,
      formReadonly: false,
      curFieldSetIndex: -1,
      showAll: false,
      debug: true,
    };
  },
  computed: {
    layout() {
      return this.model.getModelLayout(this.layoutId);
    },
    fieldSets() {
      return this.layout.fieldSets;
    },
    headerComponents() {
      return this.layout.headerComponents;
    },
    onLastFieldSet() {
      return (
        this.fieldSets.filter(
          (fieldSet, index) =>
            fieldSet.isEnabled && index > this.curFieldSetIndex
        ).length === 0
      );
    },
    reviewFieldSetIndex() {
      // second to last
      return this.fieldSets.length - 2;
    },
  },
  mounted() {
    this.next();
  },
  watch: {
    model: {
      immediate: true,
      handler() {
        this.hasUnsavedChanges = false;
        this.formReadonly = this.readMode || this.model.isReadonlyToUser();

        if (!this.model || !this.debug) return;
        const savedVal = localStorage.getItem("form-" + this.model.typeLabel);
        if (!savedVal) return;
        this.model.set(JSON.parse(savedVal));
      },
    },
    hasUnsavedChanges: {
      handler() {
        this.$nextTick(() => {
          this.$emit("update:hasUnsavedChanges", this.hasUnsavedChanges);
        });
      },
    },
  },
  methods: {
    save() {
      if (this.validate()) {
        // alert(
        //   "this button doesn't do anything yet besides checking required fields."
        // );
        // return;
        this.saving = true;
        this.model
          .submitForm()
          .then((res) => {
            this.saving = false;
            if (res != "1") return;

            this.$emit("saved");
            this.hasUnsavedChanges = false;

            this.$router.push({
              path: "/submitted",
            });
          })
          .catch(() => {
            // server error
            this.saving = false;
          });
      }
    },
    back(extraSteps = 0) {
      // find next fieldset that is enabled
      const target = Math.max(1, this.curFieldSetIndex - extraSteps);
      this.curFieldSetIndex = _.findLastIndex(
        this.fieldSets,
        (fieldSet, index) => index < target && fieldSet.isEnabled
      );
      this.curFieldSet = this.fieldSets[this.curFieldSetIndex];
    },
    next(skipValidation, extraSteps = 0) {
      if (skipValidation || this.validate()) {
        const target = Math.min(
          this.fieldSets.length - 2,
          this.curFieldSetIndex + extraSteps
        );
        // find next fieldset that is enabled
        this.curFieldSetIndex = this.fieldSets.findIndex((fieldSet, index) => {
          return index > target && fieldSet.isEnabled;
        });

        this.curFieldSet = this.fieldSets[this.curFieldSetIndex];
      }
    },
    validate() {
      const ref = this.$refs["fieldset" + this.curFieldSetIndex];
      if (!ref || ref.length === 0) return true;
      this.isValid = ref[0].validate();
      return this.isValid;
    },
    setUnsaved() {
      this.hasUnsavedChanges = true;
      this.isValid = true;
      this.$emit("setUnsaved");

      // TODO: maybe advance step
    },
    confirmLeave() {
      return window.confirm(
        "You have unsaved changes to your form submission. Are you sure you want to leave this page?"
      );
    },

    confirmStayInUnsavedForm() {
      return this.hasUnsavedChanges && !this.confirmLeave();
    },

    beforeWindowUnload(e) {
      if (this.confirmStayInUnsavedForm()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    },
    saveLocal() {
      localStorage.setItem(
        "form-" + this.model.typeLabel,
        JSON.stringify(this.model.toApiJson())
      );
    },
    clearLocal() {
      localStorage.removeItem("form-" + this.model.typeLabel);
      location.reload();
    },
    copyLocal() {
      navigator.permissions
        .query({ name: "clipboard-write" })
        .then((result) => {
          console.log(result);
          if (result.state == "granted" || result.state == "prompt") {
            /* write to the clipboard now */

            navigator.clipboard
              .writeText(JSON.stringify(this.model.toApiJson()))
              .then(() => {
                // Alert the user that the action took place.
                // Nobody likes hidden stuff being done under the hood!
                alert("Copied to clipboard");
              });
          }
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    // If the form is unsaved and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInUnsavedForm()) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },

  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
};
