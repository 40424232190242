import { Conditional, IConditional } from "./Conditional";

export type ReactiveValueType = typeof ReactiveValue;
export type IReactiveValueType = ReactiveValueType | string;

export interface HasReactiveValue {
  isTouched: boolean;
}

export type IReactiveValue = {
  value: any;
  conditional?: IConditional;
  force?: boolean;
};

export class ReactiveValue {
  protected owner: HasReactiveValue;
  protected _conditional: Conditional | null = null;
  protected _value: any;
  public force: boolean = false;

  constructor(opts: IReactiveValue, owner: HasReactiveValue) {
    this.owner = owner;
    this._value = opts.value;

    if (typeof opts.conditional !== "undefined")
      this._conditional = Conditional.newInstance(opts.conditional, this.owner);

    if (typeof opts.force !== "undefined") this.force = opts.force;
  }

  public get isEnabled(): boolean {
    return this.value !== undefined;
  }

  public get lockProperty(): boolean {
    return this.isEnabled && this.force;
  }

  public get value(): any {
    if (this._conditional && !this._conditional.result) return undefined;

    if (!this.force && this.owner.isTouched) return undefined;

    return typeof this._value === "function"
      ? this._value(this.owner)
      : this._value;
  }

  // Factory method
  public static newInstance(
    opts: IReactiveValue | any,
    owner: HasReactiveValue
  ): ReactiveValue {
    return new ReactiveValue(opts, owner);
  }
}
