
import { CrudField } from "../../CrudField";

export default {
  props: {
    field: {
      type: CrudField,
      required: true
    }
  },
  computed: {
    telLink() {
      return "tel:" + this.field.value;
    },
    noneText() {
      return this.$config.readonlyNoneDisplay;
    }
  }
};
