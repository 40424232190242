
import { ImageField } from "../../field-types/ImageField";

export default {
  data() {
    return {
      value: null
    };
  },
  props: {
    field: {
      type: ImageField,
      required: true
    }
  },
  watch: {
    "field.value": {
      immediate: true,
      handler() {
        this.value = this.field.get(true);
      }
    }
  },
  computed: {
    uploadLabel() {
      return (this.value ? "Replace" : "Upload") + " " + this.field.label;
    },
    url() {
      if (this.value) return this.field.property.fileUrl;
      return false;
    }
  }
};
