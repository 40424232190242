
import _ from "lodash";
import { NumberField } from "../../field-types/NumberField";

export default {
  props: {
    field: {
      type: NumberField,
      required: true
    },
    inputProps: {
      type: Object,
      default: () => ({})
    }
  }
};
