
import { ModelLayoutField } from "../../field-types/ModelLayoutField";

export default {
  data() {
    return {
      layout: null
    };
  },
  props: {
    field: {
      type: ModelLayoutField,
      required: true
    },
    inputProps: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    "field.value": {
      immediate: true,
      handler: function() {
        if (this.field.isEmpty) {
          this.field.createNew();
          this.layout = this.field.layout;
        } else {
          this.field.modelInstance.hydrate().then(() => {
            this.layout = this.field.layout;
          });
        }
      }
    }
  }
};
