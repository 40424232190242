
import _ from "lodash";
import { CrudCollection } from "../../CrudCollection";
import { UniqueField } from "../../field-types/UniqueField";
import { CollectionLayout } from "../../layouts";

export default {
  data() {
    return {
      showResultsPopup: false,
      queryString: "",
      isChecking: false,
      isUnique: null,
      checkResults: [],
      resultCollectionsByType: [],
      checked: false,

      errorMsg: null,

      // throttle in case multiple fields are updated at the same time
      checkUniquenessDebounced: _.debounce(() => this.checkUniqueness, 700),
    };
  },
  props: {
    field: {
      type: UniqueField,
      required: true,
    },
    inputProps: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    async checkUniqueness() {
      this.isChecking = true;
      this.queryString = this.field.stringValue;
      this.checkResults = [];
      this.errorMsg = null;
      this.resultCollectionsByType = [];

      this.field.model.modelType[this.field.apiMethodName](
        this.field.serializedPayload
      )
        .then((res) => {
          if (res.error) {
            this.errorMsg = res.error;
            return;
          }

          this.checkResults = res.dataHydrated.slice(0, 50);

          // remove the current model from the results
          if (!this.field.model.isNew) {
            this.checkResults = this.checkResults.filter(
              (result) => result.id !== this.field.model.id
            );
          }

          this.checkResults.forEach((result) => {
            const slug = result.typeSlug;

            let collectionGroup = this.resultCollectionsByType.find(
              (c) => c.collection.model.typeSlug === slug
            );

            if (!collectionGroup) {
              let collection = new CrudCollection({
                model: result.modelType,
                remoteQuery: false,
              });

              collectionGroup = {
                slug,
                collection,
                layout: collection.model
                  .getCollectionLayout()
                  .newForCollection(collection, {
                    noNew: true,
                    useModal: true,
                    noRefresh: true,
                    useDynamicFields: false,
                  }),
              };

              this.resultCollectionsByType.push(collectionGroup);
            }

            collectionGroup.collection.append(result);
          });
        })
        .finally(() => {
          this.checked = true;
          this.isChecking = false;
        });
    },
  },
  computed: {
    collections() {},
  },
  watch: {
    "field.serializedPayload": {
      handler() {
        if (this.field.autoCheck) this.checkUniquenessDebounced();
      },
    },
  },
};
