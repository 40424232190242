
import { ChoiceField } from "../../field-types/ChoiceField";

export default {
  data() {
    return {
      value: null
    };
  },
  props: {
    field: {
      type: ChoiceField,
      required: true
    }
  },
  computed: {
    values() {
      if (!this.value) return [];
      const valueArr = Array.isArray(this.value) ? this.value : [this.value];
      return valueArr.map(val => this.field.options[val]);
    },
    noneText() {
      return this.$config.readonlyNoneDisplay;
    }
  },
  watch: {
    "field.value": {
      immediate: true,
      handler() {
        this.value = this.field.get(true);
      }
    }
  }
};
