import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { DateTimeField } from "../plugins/Cruxtify/field-types/DateTimeField";
import { NumberField } from "../plugins/Cruxtify/field-types/NumberField";
import { RelationshipField } from "../plugins/Cruxtify/field-types/RelationshipField";
import { RelationshipFieldMany } from "../plugins/Cruxtify/field-types/RelationshipFieldMany";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";

import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

export class Theme extends CrudModel {
  protected static _typeLabel = "Theme";
  protected static asProperty = "theme";
  public static api = {
    path: "themes",
    fullCache: true,
  };
  protected static routeBase = "themes";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "title",
        isPrimaryLabelField: true,
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "description",
        label: "Description",
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "number",
        label: "Theme Number",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Selected By",
        relatedModel: "User",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "movies",
        label: "Movies",
        foreignProperty: "theme",
        relatedModel: "Movie",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "movie_nights",
        label: "Movie Nights",
        foreignProperty: "theme",
        relatedModel: "MovieNight",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },

    {
      type: StringField,
      opts: {
        property: "title",
        isPrimaryLabelField: true,
      },
    },
    {
      type: NumberField,
      opts: {
        property: "number",
      },
    },
    {
      type: StringField,
      opts: {
        property: "description",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "movies",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "user",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["number", "title", "user", "description"],
      enableSearch: true,
      enableDynamicFilters: true,
      defaultSort: [
        {
          field: "number",
          order: "desc",
        },
      ],
    },
  ];
}
