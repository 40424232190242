import { CrudModel } from "../CrudModel";
import { ICrudField, ICrudFieldDefinition } from "../CrudField";
import { FieldGroupField, IFieldGroupField } from "./FieldGroupField";

export type TSubFieldOpts = Record<any, ICrudField>;
export interface IUniqueField extends IFieldGroupField {
  apiMethodName?: string;
  uniqueType?: string;
  autoCheck?: boolean;
}
export class UniqueField extends FieldGroupField {
  public static fieldName = "UniqueField";
  public static formComponentName: string = "form-unique-field";

  protected apiMethodName = "get";
  public uniqueType = "";
  public seamless = false;
  public autoCheck = false;

  constructor(opts: IUniqueField, model: CrudModel) {
    super(opts, model);

    if (typeof opts.apiMethodName !== "undefined")
      this.apiMethodName = opts.apiMethodName;

    if (typeof opts.autoCheck !== "undefined") this.autoCheck = opts.autoCheck;

    if (typeof opts.uniqueType !== "undefined")
      this.uniqueType = opts.uniqueType;
    else this.uniqueType = this.label ? this.label : "Entry";

    if (typeof opts.seamless !== "undefined") this.seamless = opts.seamless;
  }
}
