import { RelationshipProperty } from "../../property-types";
import {
  DynamicFilter,
  DynamicFilterOperator,
  DynamicFilterOpts,
} from "../DynamicFilter";

export class RelationshipFilter extends DynamicFilter {
  public disabledOperators: DynamicFilterOperator[] = [
    "contains",
    "notContains",
    "lessThan",
    "greaterThan",
  ];

  constructor(opts: DynamicFilterOpts) {
    super(opts);

    if (!this.property.isHydrated) {
    }
  }

  public getValueAsync(): Promise<any> {
    return (this.property as RelationshipProperty).hydrate().then(() => {
      return this.value;
    });
  }
}
