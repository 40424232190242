
import { CrudModel } from "../CrudModel";

export default {
  data() {
    return {
      readMode: true,
      renderKey: 0,
      snapshotId: null,
      unsavedDialogOpen: false,
      navIntentTo: null,
    };
  },
  props: {
    model: {
      type: CrudModel,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    modelLayoutId: {
      type: String,
      default: "",
    },
    showModelActions: {
      type: Boolean,
      default: false,
    },
    readmodeDefault: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
    modelLayoutOpts: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    itemDeleted() {
      this.$emit("deleted", this.model);
      this.layout.navigateAfterDelete(() => this.closeModal());
    },

    itemSaved() {
      this.$emit("saved", this.model);
      this.snapshotId = this.model.takeSnapshot();
      this.layout.navigateAfterSave(() => this.closeModal());
    },

    itemReverted() {
      this.model.restoreSnapshot(this.snapshotId);
    },

    closeModal(confirmed) {
      if (this.shouldConfirmClose && confirmed !== true) {
        return (this.unsavedDialogOpen = true);
      }

      this.$emit("close");

      if (!this.model.isNew)
        setTimeout(() => {
          this.readMode = this.readmodeDefault;
          this.renderKey++;
        }, 300);
    },

    confirmUnsaved() {
      this.unsavedDialogOpen = false;
    },

    cancelEditMode() {
      if (this.model.isNew) {
        this.$emit("cancel-new", this.model.id);
        this.closeModal();
      } else {
        this.model.restoreSnapshot(this.snapshotId);
        this.readMode = true;
      }
    },
    confirmNav() {
      this.unsavedDialogOpen = false;
      this.closeModal(true);

      if (this.navIntentTo) this.$router.push(this.navIntentTo);
    },
    cancelNav() {
      this.navIntentTo = null;
      this.unsavedDialogOpen = false;
    },

    unloadListener(e) {
      if (this.shouldConfirmClose) {
        if (
          !window.confirm(
            "You have unsaved changes. Do you want to continue to leave this page?"
          )
        ) {
          e.preventDefault();
          e.returnValue = "";
        }
      }
    },
  },
  computed: {
    readModeTogglable() {
      return !this.model.isReadonlyToUser();
    },
    typeLabel() {
      return this.model.typeLabel;
    },
    layout() {
      const layout = this.model.getModelLayout(this.modelLayoutId);

      return layout.newForModel(this.model, this.modelLayoutOpts);
    },
    singleLink() {
      return this.model.getRouteSingle();
    },
    shouldConfirmClose() {
      return (
        this.model.hasUnsavedChanges &&
        (this.showModelActions || !this.model.isValid) &&
        !this.model.wasJustSaved
      );
    },
  },
  watch: {
    model: {
      immediate: true,
      handler: function () {
        this.snapshotId = this.model.takeSnapshot();

        if (this.model.isNew) this.readMode = false;
        else this.readMode = this.readmodeDefault;
      },
    },
    open: {
      immediate: true,
      handler: function () {
        if (this.open) {
          window.addEventListener("beforeunload", this.unloadListener);
        } else {
          window.removeEventListener("beforeunload", this.unloadListener);
        }
      },
    },
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.unloadListener);
  },
};
