
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { MoneyField } from "../../field-types/MoneyField";

export default {
  data() {
    return {
      mask: createNumberMask({
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false
      })
    };
  },
  props: {
    field: {
      type: MoneyField,
      required: true
    },
    inputProps: {
      type: Object,
      default: () => ({})
    }
  }
};
