
import { CrudField } from "../../CrudField";

export default {
  data() {
    return {
      values: [],
      listLimit: 5
    };
  },
  props: {
    field: {
      type: CrudField,
      required: true
    }
  },
  computed: {
    relationLinks() {
      return this.field.collection.instances
        .slice(0, this.listLimit)
        .map(instance => {
          const route = instance.getRouteSingle();
          return {
            id: instance.id,
            url: route,
            label: instance.label,
            clickable: route && !instance.isReadonlyToUser()
          };
        });
    },
    noneText() {
      return this.$config.readonlyNoneDisplay;
    }
  },
  watch: {
    field: {
      immediate: true,
      handler: function() {
        this.field.property.hydrate().then(() => {
          this.values = this.field.get();
        });
      }
    }
  }
};
