
import { CrudField } from "../../CrudField";

export default {
  props: {
    field: {
      type: CrudField,
      required: true,
    },
  },
};
