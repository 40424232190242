import { CrudFieldQuery } from "../../CrudField";
import { CrudFieldSet, ICrudFieldSet } from "../../CrudFieldSet";
import {
  ModelLayout,
  ModelLayoutDefinition,
  ModelLayoutOpts,
} from "../ModelLayout";

export interface FieldSetGridLayoutDefinition extends ModelLayoutDefinition {
  fieldSets?: ICrudFieldSet[];
  fields?: CrudFieldQuery[];
  fullWidth?: boolean;
}

export type FieldSetGridLayoutOpts = ModelLayoutOpts &
  FieldSetGridLayoutDefinition;

export class FieldSetGridLayout extends ModelLayout {
  public static componentName = "field-set-grid-layout";
  public fieldSets: CrudFieldSet[] = [];
  public fullWidth: boolean = false;

  constructor(opts: FieldSetGridLayoutOpts) {
    super(opts);

    if (typeof opts.fieldSets !== "undefined")
      this.fieldSets = [
        ...this.fieldSets,
        ...opts.fieldSets.map(
          (fieldSetDef) =>
            new CrudFieldSet({
              ...fieldSetDef,
              model: this.model,
              disableFields: opts.disableFields,
            })
        ),
      ];

    if (typeof opts.fields !== "undefined")
      this.fieldSets.push(
        new CrudFieldSet({
          id: "fromFieldsArg",
          fields: opts.fields,
          model: this.model,
          disableFields: opts.disableFields,
        })
      );

    if (this.fieldSets.length === 0)
      this.fieldSets = [
        new CrudFieldSet({
          id: "allFields",
          model: this.model,
          disableFields: opts.disableFields,
        }),
      ];

    if (typeof opts.fullWidth !== "undefined") this.fullWidth = opts.fullWidth;
  }
}
