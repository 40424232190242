
import _ from "lodash";
import { WysiwygField } from "../../field-types/WysiwygField";

import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  HardBreak,
  // extensions
  Heading,
  History,
  HorizontalRule,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  // component
  TiptapVuetify,
  Underline,
} from "tiptap-vuetify";

export default {
  data() {
    return {
      debouncedValidation: _.debounce(this.doValidation, 500),
    };
  },
  components: { TiptapVuetify },
  props: {
    field: {
      type: WysiwygField,
      required: true,
    },
    inputProps: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    doDebouncedValidation() {
      this.debouncedValidation();
    },
    doValidation() {
      this.field.validate();
    },
  },
  computed: {
    extensions() {
      const availableExtensions = {
        History: History,
        Blockquote: Blockquote,
        Link: Link,
        Underline: Underline,
        Strike: Strike,
        Italic: Italic,
        ListItem: ListItem, // if you need to use a list (BulletList, OrderedList)
        BulletList: BulletList,
        OrderedList: OrderedList,
        Headings: [
          Heading,
          {
            // Options that fall into the tiptap's extension
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold: Bold,
        Link: Link,
        Code: Code,
        HorizontalRule: HorizontalRule,
        Paragraph: Paragraph,
        HardBreak: HardBreak, // line break on Shift + Ctrl + Enter
      };

      const enabledExtensions = [];
      this.field.extensions.forEach((extension) => {
        if (availableExtensions.hasOwnProperty(extension))
          enabledExtensions.push(availableExtensions[extension]);
        else
          console.error(
            "WYSWIYG extension not available: " + extension,
            this.field
          );
      });

      return enabledExtensions;
    },
  },
};
