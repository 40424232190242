
export default {
  props: {
    fields: {
      type: Array,
      required: true
    },
    fieldProps: {
      type: Object,
      default: () => ({})
    },
    rowClass: {
      type: [Object, String],
      default: ""
    },
    colClass: {
      type: [Object, String],
      default: ""
    }
  }
};
