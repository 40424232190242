
import { CrudCollection } from "../../CrudCollection";

export default {
  data() {
    return { loading: false };
  },
  props: {
    collection: {
      type: CrudCollection,
      required: true,
    },
  },
  methods: {
    downloadExport() {
      this.loading = true;
      this.collection.model
        .export(this.collection.remoteQueryValues)
        .then((data) => {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            this.collection.model.typeLabelPlural +
              "-export-" +
              Date.now() +
              ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
