
import { CrudField } from "../../CrudField";

export default {
  data() {
    return {
      description: ""
    };
  },
  props: {
    field: {
      type: CrudField,
      required: true
    }
  },
  watch: {
    field: {
      immediate: true,
      handler() {
        this.description = this.field.description;
      }
    }
  }
};
