import { CrudProperty, ICrudProperty } from "../CrudProperty";

import dayjs, { OpUnitType } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { CrudModel } from "../CrudModel";
dayjs.extend(relativeTime);
dayjs.extend(utc);

export interface IDateTimeProperty extends ICrudProperty {
  displayFormat?: string;
  displayRelative?: boolean;
}
export class DateTimeProperty extends CrudProperty {
  public displayFormat: string = "M/D/YYYY h:mm A";
  protected displayRelative = false;

  constructor(opts: IDateTimeProperty, model: CrudModel) {
    super(opts, model);

    // defaults
    if (typeof opts.sortable === "undefined") this.sortable = true;

    if (typeof opts.displayFormat !== "undefined")
      this.displayFormat = opts.displayFormat;

    if (typeof opts.displayRelative !== "undefined")
      this.displayRelative = opts.displayRelative;
  }

  public get stringValue(): string {
    if (!this.value) return super.stringValue;

    if (this.displayRelative) return dayjs(this.value).fromNow();

    return dayjs(this.value).format(this.displayFormat);
  }

  public coerceValue(val: unknown): any {
    if (!val) return null;

    return super.coerceValue(dayjs(val as string).toDate());
  }

  protected static compareResolution: OpUnitType = "second";
  public compareValues(a: any, b: any): number {
    const aCoerced = this.coerceValue(a);
    const bCoerced = this.coerceValue(b);

    if (
      a === b ||
      dayjs(aCoerced).isSame(
        dayjs(bCoerced),
        (this._classRef as typeof DateTimeProperty).compareResolution
      )
    )
      return 0;
    return super.compareValues(aCoerced, bCoerced);
  }

  public get serializedValue() {
    return this.value ? this.value.toISOString() : this.value;
  }

  public static now() {
    return dayjs.utc().toDate();
  }
}
