
import { WysiwygField } from "../../field-types/WysiwygField";

export default {
  data() {
    return {
      innerHtml: ""
    };
  },
  props: {
    field: {
      type: WysiwygField,
      required: true
    }
  },
  watch: {
    "field.value": {
      immediate: true,
      handler() {
        this.innerHtml = this.field.get();
      }
    }
  }
};
