
import { CrudModel } from "../../CrudModel";

export default {
  props: {
    model: {
      type: CrudModel,
      required: true,
    },
  },
};
