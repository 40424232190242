
import * as dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import _ from "lodash";
import { DateTimeField } from "../../field-types/DateTimeField";
dayjs.extend(customParseFormat);

export default {
  data() {
    return {
      menu: false,
      dateValue: null,
      pickerValue: null,
      timeValue: null,
      timeFormatRule: value => {
        const parsed = this.getParsedTimeDayjs(value);
        return parsed.isValid;
      },
      // method defined here to get unique debounce func
      // (https://stackoverflow.com/questions/45908853/vuejs-2-debounce-on-multiple-components)
      updateField: _.debounce(() => {
        const timeValue = this.timeValue ? this.getParsedTimeDayjs() : dayjs();
        if (!timeValue.isValid()) return;

        const dateValue = dayjs(this.pickerValue);
        if (!dateValue.isValid()) return;

        const datetimeValue = dateValue
          .hour(timeValue.hour())
          .minute(timeValue.minute())
          .second(0);

        this.field.set(datetimeValue);
        this.$emit("change", this.field);

        this.updateComponentValues();
      }, 500)
    };
  },
  props: {
    field: {
      type: DateTimeField,
      required: true
    },
    inputProps: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    this.updateComponentValues();
  },
  methods: {
    updateComponentValues() {
      const dateVal = this.field.get(true);
      const dayjsVal = dayjs(dateVal);

      this.dateValue = dateVal ? dayjsVal.format("MM/DD/YYYY") : null;
      this.pickerValue = dateVal ? dayjsVal.format("YYYY-MM-DD") : null;
      this.timeValue = dateVal ? dayjsVal.format("h:mm A") : null;
    },
    getParsedTimeDayjs(value) {
      if (!value) value = this.timeValue;

      return dayjs(("" + value).toLowerCase(), [
        "hh:mma",
        "h:mma",
        "hh:mm a",
        "h:mm a",
        "hh:mm",
        "h:mm",
        "h a",
        "ha"
      ]);
    }
  },
  watch: {
    "field.property": {
      immediate: true,
      handler: function() {
        this.updateComponentValues();
      }
    },
    "field.property.value": {
      handler: function() {
        this.updateComponentValues();
      }
    }
  }
};
