
import { CollectionLayout } from "../layouts/CollectionLayout";

export default {
  data() {
    return {
      dialogDelete: false,
      singleModel: new this.layout.collection.model(),
      singleModalOpen: false,
      deletingItem: null,
      stashedRoute: null,
      disableNew: null,
      initialLoadComplete: false,
    };
  },
  props: {
    layout: {
      type: CollectionLayout,
      required: true,
    },
    showModelActions: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    editItem(itemId) {
      if (!this.layout.useModal)
        return this.$router.push({
          path: this.layout.collection.model.getRouteSingle(itemId),
        });

      this.singleModel = this.layout.collection.getItem(itemId);

      if (this.showModelActions && this.singleModel.getRouteSingle()) {
        this.stashedRoute = location.toString();
        history.pushState({}, null, this.singleModel.getRouteSingle());
      }

      this.singleModalOpen = true;
    },

    addNew() {
      if (this.layout.inlineEdit || this.expansionEnabled) {
        const newRowModel = this.layout.collection.newItem();

        // TODO: expand new item if expansion enabled
      } else if (this.layout.useModal && !this.layout.navigateForNew) {
        this.singleModel = this.layout.collection.newItem();
        this.singleModalOpen = true;
      } else {
        this.$router.push({
          path: this.layout.collection.newItemRoute,
        });
      }
    },

    restoreRoute() {
      if (!this.stashedRoute) return;

      history.pushState({}, null, this.stashedRoute);
      this.stashedRoute = null;
    },

    modalClosed() {
      this.restoreRoute();
      this.singleModalOpen = false;
    },

    cancelNew(modelId) {
      this.singleModel = null;
      this.layout.collection.deleteItem(modelId);
    },

    deleteItem(itemId) {
      return this.layout.collection.deleteItem(itemId);
    },

    deleteItemInit(model) {
      if (this.showModelActions) {
        this.deletingItem = model;
        this.dialogDelete = true;
      } else {
        this.layout.collection.markItemForDeletion(model.id);
      }
    },

    restoreItem(model) {
      return this.layout.collection.restoreItem(model.id);
    },

    deleteItemConfirm() {
      this.deleteItem(this.deletingItem.id).then((res) => {
        this.closeDelete();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refresh() {
      this.layout.collection.forceFetch();
    },

    saveInlineChanges() {
      this.layout.collection.saveChanges();
    },

    applyFilters() {
      this.layout.collection.applyStagedFilters();
    },

    clearFilters() {
      this.layout.collection.clearVisibleFiltersImmediately();
    },
  },

  computed: {
    hasFiltersRow() {
      return (
        this.layout.collection.visibleFilters.length >
        (this.layout.enableDynamicFilters ? 0 : 1)
      );
    },
    showLayoutComponent() {
      return (
        this.initialLoadComplete ||
        (!(this.layout.collection.loading && this.layout.collection.isEmpty) &&
          (!this.layout.hideWhenEmpty || !this.layout.collection.isEmpty))
      );
    },
    hasCollectionActions() {
      return (
        !this.disableNew ||
        !this.layout.noRefresh ||
        this.layout.collectionActionComponents.length > 0 ||
        this.$slots["collection-actions"] ||
        this.$scopedSlots["collection-actions"]
      );
    },
    showCollectionSaveActions() {
      return (
        this.layout.inlineEdit &&
        this.showModelActions &&
        this.layout.collection.hasUnsavedChanges
      );
    },
    modalLayoutOpts() {
      return {
        disableFields: this.layout.disableFields,
      };
    },
  },

  watch: {
    "layout.collection.model": {
      immediate: true,
      handler() {
        this.disableNew =
          !this.layout.collection.model.userCanCreateNew() ||
          this.layout.collection.model.isReadonlyToUser() ||
          this.layout.noNew;
      },
    },
    "layout.collection.filterQueryValues": {
      handler() {
        this.layout.collection.setQueryOption("page", 1);
        this.layout.collection.fetchDebounced();
      },
    },
    "layout.collection.loading": {
      handler() {
        if (!this.layout.collection.loading) {
          this.initialLoadComplete = true;
        }
      },
    },
  },
};
