
import { TabbedCollectionsLayout } from "../../layouts";

export default {
  props: {
    layout: {
      type: TabbedCollectionsLayout,
      required: true,
    },
  },
};
