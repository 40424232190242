import { ICrudField } from "../CrudField";
import { CrudModel } from "../CrudModel";
import { NumberField } from "./NumberField";
import { StringField } from "./StringField";
import {
  IStructuredFieldGroupField,
  ISubFieldDefinition,
  StructuredFieldGroupField
} from "./StructuredFieldGroupField";

export enum AddressFieldsEnum {
  STREET_1,
  STREET_2,
  CITY,
  DIVISION_1,
  DIVISION_2,
  POSTAL_CODE,
  COUNTRY,
  LATITUDE,
  LONGITUDE
}
export interface IAddressSubFieldDefinition extends ISubFieldDefinition {
  subFieldId: AddressFieldsEnum;
}
export interface IAddressField extends IStructuredFieldGroupField {
  disabledSubFields?: AddressFieldsEnum[];
}
export class AddressField extends StructuredFieldGroupField {
  public static fieldName = "AddressField";
  public static viewComponentName: string = "view-address-field";
  public static icon = "mdi-map-marker";

  constructor(opts: IAddressField, model: CrudModel) {
    super(opts, model);

    // defaults
    if (typeof opts.fieldWidth === "undefined") this.fieldWidth = "large";

    // disable lat/long in stringVal if not explicitly defined
    if (typeof opts.stringValueExcludedSubfields === "undefined")
      this.stringValueExcludedSubfields = [
        AddressFieldsEnum.LATITUDE,
        AddressFieldsEnum.LONGITUDE
      ].map(
        structuredId => this.getSubFieldByStructuredId(structuredId) as string
      );
  }

  // public label = "Address";
  public static structuredSubFieldDefs = [
    {
      subFieldId: AddressFieldsEnum.STREET_1,
      type: StringField,
      opts: {
        id: "address_street_1",
        fieldWidth: "full",
        label: "Street"
      } as ICrudField
    },
    {
      subFieldId: AddressFieldsEnum.STREET_2,
      type: StringField,
      opts: {
        id: "address_street_2",
        fieldWidth: "full",
        label: "Street 2"
      } as ICrudField
    },
    {
      subFieldId: AddressFieldsEnum.CITY,
      type: StringField,
      opts: {
        id: "address_city",
        label: "City"
      } as ICrudField
    },
    {
      subFieldId: AddressFieldsEnum.DIVISION_1,
      type: StringField,
      opts: {
        id: "address_state",
        label: "State"
      } as ICrudField
    },
    {
      subFieldId: AddressFieldsEnum.DIVISION_2,
      type: StringField,
      opts: {
        id: "address_substate",
        label: "Substate"
      } as ICrudField
    },
    {
      subFieldId: AddressFieldsEnum.POSTAL_CODE,
      type: StringField,
      opts: {
        id: "address_postal",
        label: "Postal Code",
        fieldWidth: "small"
      } as ICrudField
    },
    {
      subFieldId: AddressFieldsEnum.COUNTRY,
      type: StringField,
      opts: {
        id: "address_country",
        label: "Country",
        fieldWidth: "small"
      } as ICrudField
    },
    {
      subFieldId: AddressFieldsEnum.LATITUDE,
      type: NumberField,
      opts: {
        id: "address_latitude",
        label: "Latitude",
        fieldWidth: "small"
      } as ICrudField
    },
    {
      subFieldId: AddressFieldsEnum.LONGITUDE,
      type: NumberField,
      opts: {
        id: "address_longitude",
        label: "Longitude",
        fieldWidth: "small"
      } as ICrudField
    }
  ];
}
