import { StringProperty } from "./StringProperty";

export class PhoneProperty extends StringProperty {
  public get stringValue(): string {
    if (!this.value) return super.stringValue;

    let stringValue = this.value;

    // Remove the country code (leading '1')
    if (stringValue.startsWith("1")) stringValue = stringValue.substr(1);

    // Format the number using a regular expression and replacement pattern
    const formattedNumber = stringValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );

    return this.decorate(formattedNumber);
  }
}
