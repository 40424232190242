import { DateProperty } from "../property-types";
import { DateTimeField } from "./DateTimeField";
export class DateField extends DateTimeField {
  public static fieldName = "DateField";
  public static formComponentName: string = "form-date-field";

  protected static readonly defaultPropertyType = DateProperty;

  protected static icon = "mdi-calendar";
}
