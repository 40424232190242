import { CrudModel, CrudModelType } from "../CrudModel";

import {
  CrudProperty,
  CrudPropertyGet,
  CrudPropertyQuery,
  ICrudProperty,
  ICrudPropertyGet,
} from "../CrudProperty";

import {
  CrudCollection,
  ICrudCollection,
  ICrudCollectionItemValue,
  ICrudCollectionValues,
} from "../CrudCollection";

import { CrudPropertyFilter } from "../filters/CrudPropertyFilter";
export interface IRelationshipPropertyMany extends ICrudProperty {
  relatedModel: CrudModelType;
  foreignProperty?: string;
  stringValueGlue?: string;
  newModelDefaults?: Record<string, any> | Function; // TODO: refactor with generics
}
export class RelationshipPropertyMany extends CrudProperty {
  public newModelDefaults: Record<string, any> | Function = {};

  private _foreignPropertyName: string = "";
  public get foreignPropertyName(): string {
    if (!this._foreignPropertyName) {
      if (this.model)
        this._foreignPropertyName = this.model.getAsProperty(true);
      else
        console.error(
          "foreignPropertyName is not set on in RelationshipPropertyMany def:",
          this
        );
    }
    return this._foreignPropertyName;
  }

  private _foreignProperty: CrudProperty | null = null;
  public get foreignProperty(): CrudProperty {
    if (this._foreignProperty === null) {
      const foreignProperty = this.staticModelInstance.findProperty(
        this.foreignPropertyName
      );
      if (!(foreignProperty instanceof CrudProperty)) {
        throw new Error(
          `Could not find foreign property ${this.foreignPropertyName} on model ${this.staticModelInstance.typeLabel}`
        );
      }

      this._foreignProperty = foreignProperty;
    }

    return this._foreignProperty;
  }

  protected _collection: CrudCollection | null = null;
  public get collection() {
    if (this._collection === null) {
      const collectionOpts: ICrudCollection = {
        model: this.relatedModel,
      };

      if (this.model?.isNew || !this.model) {
        collectionOpts.remoteQuery = false;
      } else {
        collectionOpts.remoteQueryFilters = [
          new CrudPropertyFilter({
            name: "relationshipOwningModel",
            isStatic: true,
            isHidden: true,
            property: this.foreignProperty.clone(this.model),
          }),
        ];
      }

      if (typeof this.newModelDefaults === "function")
        collectionOpts.newModelDefaults = function (
          this: RelationshipPropertyMany
        ) {
          // @ts-ignore
          return this.newModelDefaults(this.model);
        }.bind(this);
      else if (typeof this.newModelDefaults === "object")
        collectionOpts.newModelDefaults = this.newModelDefaults;

      this._collection = new CrudCollection(collectionOpts);
    }

    return this._collection;
  }

  public relatedModel: CrudModelType;

  protected _staticModelInstance?: CrudModel;
  public get staticModelInstance() {
    if (!this._staticModelInstance)
      this._staticModelInstance = new this.relatedModel();
    return this._staticModelInstance as CrudModel;
  }
  public set staticModelInstance(val) {
    this._staticModelInstance = val;
  }

  protected _value: number[] = [];
  protected removeAll = false;

  constructor(opts: IRelationshipPropertyMany, model: CrudModel) {
    super(opts, model);

    this.relatedModel = CrudModel.getModelType(opts.relatedModel);

    if (typeof opts.foreignProperty !== "undefined")
      this._foreignPropertyName = opts.foreignProperty; // TODO: refactor pluralization stuff in util func

    if (typeof opts.newModelDefaults !== "undefined")
      this.newModelDefaults = opts.newModelDefaults;

    if (typeof opts.stringValueGlue !== "undefined")
      this.stringValueGlue = opts.stringValueGlue;
  }

  private _getRootProp(propString: string) {
    return propString.split(".")[0];
  }

  public get(opts?: CrudPropertyGet) {
    return super.get(opts);
  }

  public get value() {
    if (this.reactiveValue?.isEnabled) {
      // mark as an unsaved changed if this value is different than _value
      if (
        this.compareValues(this.reactiveValue.value, this.collection.ids) !== 0
      ) {
        this._hasUnsavedChanges = true;
        this._isHydrated = true;
      }

      return this.reactiveValue.value;
    }
    return this.collection.ids;
  }

  public takeSnapshot(snapshotId?: number) {
    snapshotId = super.takeSnapshot(snapshotId);

    this.collection.instances.forEach((instance) =>
      instance.takeSnapshot(snapshotId)
    );

    return snapshotId;
  }

  public restoreSnapshot(snapshotId?: number) {
    if (
      this.collection.remoteQuery &&
      (!this.hasSnapshot(snapshotId) ||
        this.getSnapshot(snapshotId).data.length === 0)
    ) {
      this.collection.forceFetch();
    } else {
      this.collection.unsavedInstances.forEach((instance) => {
        if (instance.hasSnapshot(snapshotId))
          instance.restoreSnapshot(snapshotId);
        else
          instance.isNew
            ? this.collection.removeItem(instance.id)
            : instance.hydrate();
      });
    }

    this.collection.markAsSaved();
  }

  public set(
    val: CrudCollection | ICrudCollectionValues | null,
    skipMarkingAsUnsaved = false
  ) {
    if (val === null) {
      // we empty the collection without setting is as unsaved
      // since we're sending over our own value for removing all.
      // if we didn't, it would send instructions to removeAll as
      // well as the specific ids to remove
      this.collection.set(null, true);
      this.removeAll = true;

      return super.set(this.value, skipMarkingAsUnsaved);
    }

    if (val instanceof CrudCollection) {
      this._collection = val;
    } else {
      this.collection.set(val as ICrudCollectionValues, skipMarkingAsUnsaved);
    }

    super.set(this.value, skipMarkingAsUnsaved);
  }

  public get typedValue() {
    return this.collection.instances;
  }

  public get serializedValue() {
    return this.collection.serializedValue;
  }

  public get serializedChangesValue() {
    const collectionWasReplaced =
      this._hasUnsavedChanges && !this.collection.hasUnsavedChanges;

    return collectionWasReplaced
      ? this.value
      : [...this.collection.serializedChanges, ...(this.removeAll ? [0] : [])];
  }

  public get hasUnsavedChanges(): boolean {
    return (
      this.isHydrated &&
      (this._hasUnsavedChanges || this.collection.hasUnsavedChanges)
    );
  }

  public markAsSaved() {
    super.markAsSaved();

    this.collection.markAsSaved();
  }

  protected stringValueGlue = ", ";
  public get stringValue(): string {
    return this.collection.instances
      .map((instance) => instance.label)
      .join(this.stringValueGlue);
  }

  public append(val: ICrudCollectionItemValue) {
    this.collection.append(val);
  }

  public remove(val: CrudModel | number) {
    let itemId = val;
    if (typeof val == "object") itemId = (val as CrudModel).id;

    this.collection.removeItem(itemId as number);
  }

  public deleteItem(val: CrudModel | number) {
    let itemId = val;
    if (typeof val == "object") itemId = (val as CrudModel).id;

    this.collection.deleteItem(itemId as number);
  }

  public toPlainObject(
    opts: ICrudPropertyGet = {
      decorated: true,
      formatted: true,
    }
  ) {
    if (!opts.decorated && !opts.formatted) return this.value;
    return this.collection.toPlainObject(opts);
  }

  public getForeignRelationship() {
    const foreignRelationship = this.relatedModel.findProperty(
      this.foreignPropertyName
    );
    if (!foreignRelationship)
      throw new Error(
        `Foreign relationship not defined for property "${this.label}" on model "${this.model?.typeLabel}"`
      );
    return foreignRelationship;
  }

  private _stripRootProp(propString: string): string {
    const propPieces = propString.split(".");
    propPieces.shift();
    return propPieces.join(".");
  }

  public findProperties(
    property: CrudPropertyQuery[] | CrudPropertyQuery
  ): CrudProperty[] | undefined {
    if (!Array.isArray(property)) property = [property];

    if (super.findProperties(property)) return [this];

    if (
      property.some(
        (prop) => prop == this.serializedName || prop == this.typedName
      )
    )
      return [this];

    const propertiesStringsWithoutRootProp = property
      .filter((arg) => typeof arg === "string")
      .filter(
        (stringArg) => this._getRootProp(stringArg as string) == this.name
      )
      .map((stringArg) => this._stripRootProp(stringArg as string));

    return propertiesStringsWithoutRootProp.length > 0
      ? this.collection.instances
          .map((model) =>
            model.findProperties(propertiesStringsWithoutRootProp)
          )
          .flat()
          .filter((field) => field)
      : undefined;
  }

  public get isEmpty() {
    return this.collection.isEmpty;
  }

  public async hydrate(forceRefresh = false) {
    if (this.isHydrated && !forceRefresh) return;

    const fetchMethod = forceRefresh ? "fetch" : "fetchIfUnhydrated";

    return this.collection[fetchMethod]().then(() => {
      this._isHydrated = true;
    });
  }
}
