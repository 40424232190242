import { CrudField } from "../CrudField";
import { CrudPropertyGet } from "../CrudProperty";
import { CheckboxField, ICheckboxField } from "./CheckboxField";

export class YesNoField extends CheckboxField {
  public static fieldName = "YesNoField";
  public static formComponentName: string = "form-checkbox-field";

  public multiple = false;
  public checkboxSize = "xsmall";

  protected _options = ["Yes", "No"];

  public get(opts?: CrudPropertyGet) {
    const val = super.get(opts);
    if (val === null) return [];
    return val ? "Yes" : "No";
  }
}
