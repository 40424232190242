import { CrudFilter, ICrudFilter } from "../CrudFilter";
import { CrudProperty } from "../CrudProperty";

export interface ICrudPropertyFilter extends ICrudFilter {
  property: CrudProperty;
  queryName?: string;
}

export class CrudPropertyFilter extends CrudFilter {
  public property: CrudProperty;

  constructor(opts: ICrudPropertyFilter) {
    super(opts);

    this.property = opts.property;
    this._queryName =
      typeof opts.queryName === "undefined"
        ? this.property.serializedChangesName
        : opts.queryName;
  }

  public get query(): object {
    return { [this.queryName]: this.serializedValue };
  }

  public get serializedValue(): string {
    return this.property.serializedValue;
  }

  public setQuery(value: any) {
    super.setQuery(value);

    // this filter is tied to a property, so we need to set the property's value
    this.property.set(value);
  }

  public clear() {
    return this.property.clear();
  }

  public isSet() {
    return !this.property.isEmpty;
  }

  public get isVisible() {
    return super.isVisible && this.property.isVisibleToUser();
  }

  public asDefaultProperty(value?: any) {
    return this.property;
  }

  public static fromProperty(
    property?: CrudProperty,
    additionalOptions = {}
  ): CrudPropertyFilter {
    if (!property) {
      throw Error("CrudPropertyFilter: property is required");
    }

    return new CrudPropertyFilter({
      name: property.name,
      property: property,
      ...additionalOptions,
    });
  }
}
