import { INumberProperty, NumberProperty } from "./NumberProperty";
import { CrudModel } from "../CrudModel";

export class MoneyProperty extends NumberProperty {
  protected decoratorPrefix: string = "$";

  constructor(opts: INumberProperty, model: CrudModel) {
    super(opts, model);

    if (typeof opts.decimals === "undefined") this.decimals = 2;
  }
}
