// Nuxt Plugin

import Vue from "vue";

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import { api, setContext } from "./api/index";

import frag from "vue-frag";

import { initConfig } from "./crudConfig";
// @ts-ignore
import * as crudModels from "~/models";
// @ts-ignore
import * as crudFields from "~/fields";

// import { Crud } from "./Crud";

export default (context, inject) => {
  // api config
  setContext(context);
  inject("api", api);

  // core cruxtify config
  initConfig(
    {
      crudModels,
      crudFields
    },
    context
  );

  // tiptap-vuetify setup
  Vue.use(TiptapVuetifyPlugin, {
    vuetify: context.app.vuetify,
    iconsGroup: "mdi"
  });

  Vue.directive("frag", frag);
};
