import { DynamicFilter, DynamicFilterOperator } from "../DynamicFilter";

export class BooleanFilter extends DynamicFilter {
  public disabledOperators: DynamicFilterOperator[] = [
    "contains",
    "notContains",
    "lessThan",
    "greaterThan"
  ];
}
