
import { AddressFieldsEnum } from "../../field-types/AddressField";
import { GoogleAddressField } from "../../field-types/GoogleAddressField";

export default {
  data() {
    return {
      fieldGroupFieldKey: 0
    };
  },
  props: {
    field: {
      type: GoogleAddressField,
      required: true
    },
    inputProps: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    bubbleChangeEvent(field) {
      this.$emit("change", field);
    },
    gotAddress() {
      // TODO: update to account for disabled fields
      const place = this.autocomplete.getPlace();

      const streetField = this.field.getSubField(AddressFieldsEnum.STREET_1);
      const cityField = this.field.getSubField(AddressFieldsEnum.CITY);
      const stateField = this.field.getSubField(AddressFieldsEnum.DIVISION_1);
      const zipField = this.field.getSubField(AddressFieldsEnum.POSTAL_CODE);
      const latField = this.field.getSubField(AddressFieldsEnum.LATITUDE);
      const lngField = this.field.getSubField(AddressFieldsEnum.LONGITUDE);

      const newStreetVal =
        this.getPlaceTypeShortName("street_number", place) +
        " " +
        this.getPlaceTypeShortName("route", place);
      streetField.set(newStreetVal);

      cityField.set(this.getPlaceTypeShortName("locality", place));
      stateField.set(
        this.getPlaceTypeShortName("administrative_area_level_1", place)
      );
      zipField.set(this.getPlaceTypeShortName("postal_code", place));

      latField.set(place.geometry.location.lat());
      lngField.set(place.geometry.location.lng());

      this.rerenderField();
    },
    getPlaceTypeShortName(type, place) {
      const addrComponent = place.address_components.find(
        component => component.types.indexOf(type) > -1
      );

      if (!addrComponent) return "";

      return addrComponent.short_name;
    },
    bindAutocomplete() {
      // make sure $GMaps is ready
      if (!this.$GMaps.google) {
        setTimeout(() => this.bindAutocomplete(), 100);
        return;
      }

      this.$GMaps.google.then(google => {
        const autocompleteFieldId = this.field.getSubFieldByStructuredId(
          AddressFieldsEnum.STREET_1
        );

        const inputRef = this.$refs["field-group-field"].$refs["fields"].$refs[
          "field-" + autocompleteFieldId
        ][0].$el.querySelector("input");

        this.autocomplete = new google.maps.places.Autocomplete(inputRef, {
          componentRestrictions: { country: ["us", "ca"] }
        });
        inputRef.setAttribute("placeholder", " ");

        this.autocomplete.addListener("place_changed", this.gotAddress);
      });
    },
    rerenderField() {
      this.fieldGroupFieldKey++;
      this.bindAutocomplete();
    }
  },
  watch: {
    field: {
      immediate: true,
      handler() {
        this.bindAutocomplete();
      }
    }
  }
};
