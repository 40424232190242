import {
  IPropertyConditional,
  HasPropertyConditional,
  PropertyConditional,
} from "./PropertyConditional";

export class PropertyChangesConditional extends PropertyConditional {
  constructor(opts: IPropertyConditional, owner: HasPropertyConditional) {
    super(opts, owner);
  }

  protected get compare() {
    return !this.property
      ? super.compare
      : this.property.serializedChangesValue;
  }

  public get result(): boolean {
    if (!this.property) return super.result;

    return this.property.hasUnsavedChanges ? super.result : false;
  }
}
