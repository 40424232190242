import { CrudProperty } from "./CrudProperty";

export interface ICrudFilter {
  name: string;
  query?: object;
  queryName?: string;
  queryValue?: string;
  isStatic?: boolean;
  isHidden?: boolean;
  componentName?: string;
  componentOpts?: object;
}

export class CrudFilter {
  public name: string = "";

  protected _queryName?: string;
  public get queryName() {
    return this._queryName ? this._queryName : this.name;
  }

  protected queryValue: any;

  public isStatic: boolean = false;
  public isHidden: boolean = false;
  public _componentName: string = "";
  public get componentName(): string {
    return this._componentName;
  }

  protected static componentOpts = {};
  protected _componentOpts = {};
  public get componentOpts() {
    return { ...this._componentOpts, ...this._classRef.componentOpts };
  }

  protected get _classRef(): typeof CrudFilter {
    return Object.getPrototypeOf(this).constructor;
  }

  constructor(opts: ICrudFilter) {
    this.name = opts.name;

    if (typeof opts.query !== "undefined") this._query = opts.query;

    if (typeof opts.queryName !== "undefined") this._queryName = opts.queryName;

    if (typeof opts.queryValue !== "undefined")
      this.queryValue = opts.queryValue;

    if (typeof opts.componentName !== "undefined")
      this._componentName = opts.componentName;

    Object.assign(this._componentOpts, { crudFilter: this });

    if (opts.componentOpts)
      Object.assign(this._componentOpts, opts.componentOpts);

    if (typeof opts.isStatic !== "undefined") this.isStatic = opts.isStatic;

    if (typeof opts.isHidden !== "undefined") this.isHidden = opts.isHidden;
  }

  public get isVisible() {
    return !this.isHidden && this.componentName.length > 0;
  }

  public asDefaultProperty(value?: any): CrudProperty | null {
    return null;
  }

  protected _query?: object;
  public get query(): object {
    return this._query
      ? this._query
      : {
          [this.queryName]: this.queryValue,
        };
  }

  public get queryKeys(): string[] {
    return Object.keys(this.query);
  }

  public setQuery(value: any) {
    this.queryValue = value;
  }

  public clear() {
    return this.setQuery(undefined);
  }

  public overlapsFilter(filter: CrudFilter): boolean {
    return this.queryKeys.some((key) => filter.queryKeys.includes(key));
  }

  public isSet(): boolean {
    return typeof this.queryValue !== undefined && this.queryValue !== null;
  }
}
