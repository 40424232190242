
import { FieldGroupField } from "../../field-types/FieldGroupField";

export default {
  props: {
    field: {
      type: FieldGroupField,
      required: true
    },
    inputProps: {
      type: Object,
      default: () => ({})
    },
    readMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    enabledSubFields() {
      return this.field.subFields.filter(subField => subField.isEnabled);
    },
    scootUpField() {
      return this.$config.useDetachedFieldLabels;
    }
  }
};
