import { CrudModel } from "../CrudModel";
import { CrudProperty } from "../CrudProperty";
import {
  CompareConditional,
  HasCompareConditional,
  ICompareConditional
} from "./CompareConditional";

export interface IPropertyConditional extends ICompareConditional {
  property?: string;
}

export interface HasPropertyConditional extends HasCompareConditional {
  model: CrudModel;
  property?: CrudProperty;
}

export class PropertyConditional extends CompareConditional {
  protected property: CrudProperty | undefined;

  constructor(opts: IPropertyConditional, owner: HasPropertyConditional) {
    super(opts, owner);

    let propertyQuery;

    if (opts.property) propertyQuery = opts.property;
    else if (owner.property) propertyQuery = owner.property;
    else {
      console.log({ propertyQuery, owner });
      throw new Error(
        "PropertyConditional must have a property defined on either itself or its owner"
      );
    }

    if (!owner.model) {
      console.log({ propertyQuery, owner });
      throw new Error(
        "PropertyConditional must have a model defined on its owner"
      );
    }

    const resolvedProperty = CrudProperty.findOrCreateInstance({
      model: owner.model,
      prop: propertyQuery
    });

    /*
    owner.model
      .awaitProperty(propertyQuery)
      .then(property => {
        this.property = property;
        console.log(this);
      })
      .catch(err => {
        console.error(err, opts, this);
        throw new Error("Unable to resolve property: " + propertyQuery);
      });
      */

    this.property = resolvedProperty;
  }

  public get result(): boolean {
    if (!this.property) return false;

    return super.result;
  }

  protected get compare() {
    return this.property ? this.property.value : undefined;
  }
}
