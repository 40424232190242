import { render, staticRenderFns } from "./TabbedLayout.vue?vue&type=template&id=4fd17bde"
import script from "./TabbedLayout.vue?vue&type=script&lang=js"
export * from "./TabbedLayout.vue?vue&type=script&lang=js"
import style0 from "./TabbedLayout.vue?vue&type=style&index=0&id=4fd17bde&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VIcon,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})
