
import { RelationshipField } from "../../field-types/RelationshipField";
import { RelationshipFieldMany } from "../../field-types/RelationshipFieldMany";
import { RelationshipProperty } from "../../property-types";

export default {
  data() {
    const addExistingProperty = new RelationshipProperty({
      relatedModel: this.field.property.relatedModel,
    });

    return {
      loading: false,
      value: null,
      renderKey: Date.now(),
      addExistingProperty,
      componentFieldExisting: new RelationshipField({
        label: "Add Existing",
        property: addExistingProperty,
      }),
      collectionLayout: null,
    };
  },
  props: {
    field: {
      type: RelationshipFieldMany,
      required: true,
    },
    inputProps: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    property() {
      return this.field.property;
    },
    relatedModel() {
      return this.property.relatedModel;
    },
    relationshipIsManyToMany() {
      const foreignRelationship = this.property.getForeignRelationship();
      return foreignRelationship.isOfType("RelationshipPropertyMany");
    },
    isReadMode() {
      return !!this.inputProps["read-only"];
    },
  },
  methods: {
    appendSelected() {
      if (this.addExistingProperty.isEmpty) return;

      this.field.collection.append(this.addExistingProperty.typedValue);
      this.componentFieldExisting.clear();
    },
    removeItem(model) {
      this.field.collection.removeItem(model.id);
    },
    updateCollectionLayout() {
      const collectionLayout = this.relatedModel.getCollectionLayout(
        this.field.collectionLayoutId
      );

      const newLayoutProps = {
        useModal: true,
        navigateForNew: false,
        enableDynamicFilters: false,
        noNew: this.field.isReadonlyToUser(),
        noRefresh: this.field.isReadonlyToUser(),
        modalOptions: {
          "readmode-default": this.isReadMode,
        },
        disableFields: [this.field.property.foreignPropertyName],
      };

      // enabled item actions
      let enabledItemActions = collectionLayout.enabledItemActions;
      if (
        !this.relationshipIsManyToMany &&
        !this.relatedModel.isReadonlyToUser()
      ) {
        enabledItemActions.push("delete");
      }

      if (this.isReadMode) {
        enabledItemActions = enabledItemActions.filter(
          (action) => action !== "delete"
        );
      }

      newLayoutProps.enabledItemActions = enabledItemActions;

      // hide filters
      if (this.field.hideFilters) {
        newLayoutProps.filterFields = null;
      }

      // merge with explicit layout opts
      if (this.field.collectionLayoutOpts) {
        Object.assign(newLayoutProps, this.field.collectionLayoutOpts);
      }

      // force readmodeDefault if this is in readmode & navigateForNew
      if (this.isReadMode) {
        newLayoutProps.modalOptions = Object.assign(
          newLayoutProps.modalOptions || {},
          { readmodeDefault: true }
        );

        newLayoutProps.navigateForNew = true;
      }

      // disable inlineedit if in readmode
      if (this.isReadMode) {
        newLayoutProps.inlineEdit = false;
      }

      this.collectionLayout = collectionLayout.newForCollection(
        this.field.collection,
        newLayoutProps
      );
    },
  },
  watch: {
    property: {
      immediate: true,
      handler(val) {
        this.property.hydrate();
      },
    },
    "addExistingProperty.value": {
      immediate: true,
      handler(val) {
        this.appendSelected();
      },
    },
    isReadMode: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          this.updateCollectionLayout();
          this.renderKey++;
        });
      },
    },
  },
};
