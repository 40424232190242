
import { TabbedModelLayout } from "../../../layouts";

export default {
  props: {
    layout: {
      type: TabbedModelLayout,
      required: true
    },
    readMode: {
      type: Boolean,
      default: false
    }
  }
};
