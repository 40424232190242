
import { ModelLayout } from "../layouts/ModelLayout";

export default {
  props: {
    layout: {
      type: ModelLayout,
      required: true
    },
    readMode: {
      type: Boolean,
      default: false
    },
    suppressHeaderComponents: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  }
};
