import { CrudModel } from "../CrudModel";
import { CrudProperty, ICrudProperty } from "../CrudProperty";

export interface IFileProperty extends ICrudProperty {
  prependUploadsUrl?: boolean;
}

export class FileProperty extends CrudProperty {
  protected _value: string | File[] | null = null;

  public prependUploadsUrl: boolean = true;

  public get serializedValue() {
    return typeof this.value === "string" ? this.get(true) : this.value;
  }

  public get fileUrl() {
    return this.prependUploadsUrl && this.$nuxt.$config.uploadsURL
      ? `${this.$nuxt.$config.uploadsURL}/${this.serializedValue}`
      : this.serializedValue;
  }

  constructor(opts: IFileProperty, model?: CrudModel) {
    super(opts, model);

    if (typeof opts.prependUploadsUrl !== "undefined") {
      this.prependUploadsUrl = opts.prependUploadsUrl;
    }
  }
}
