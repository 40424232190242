import { CrudField } from "../CrudField";
import { FileProperty } from "../property-types/FileProperty";

export class FileField extends CrudField {
  public static fieldName = "FileField";

  public static formComponentName: string = "FormFileField";
  public static viewComponentName: string = "ViewFileField";

  protected static icon = "mdi-file-outline";

  protected static readonly defaultPropertyType = FileProperty;
}
