export class debounce {
  protected static debouncedRequests = new Map();
  protected static clearCacheTimeouts = new Map();

  public static async debounceRequest(requestArgs, requestFn) {
    // console.log(requestArgs);
    const cacheStr = JSON.stringify(requestArgs);
    if (!this.debouncedRequests.has(cacheStr)) {
      this.debouncedRequests.set(
        cacheStr,
        requestFn().then((res) => {
          this.clearCacheTimeouts.set(
            cacheStr,
            setTimeout(() => {
              this.debouncedRequests.delete(cacheStr);
              this.clearCacheTimeouts.delete(cacheStr);
            }, 1000)
          );

          return Promise.resolve(res);
        })
      );
    }

    return this.debouncedRequests.get(cacheStr);
  }
}
