import { CrudModel } from "../CrudModel";
import {
  CrudProperty,
  ICrudPropertyType,
  ICrudProperty,
  CrudPropertyGet
} from "../CrudProperty";

export interface IComputedProperty extends ICrudProperty {
  computeValue: Function;
  computeStringValue?: Function;
  presentationType?: ICrudPropertyType;
}

export class ComputedProperty extends CrudProperty {
  protected computeValue: Function;
  protected presentationProxy: CrudProperty | undefined;

  protected computeStringValue: Function | null = null;

  constructor(opts: IComputedProperty, model?: CrudModel) {
    super(opts, model);

    this.computeValue = opts.computeValue;

    if (typeof opts.computeStringValue !== "undefined")
      this.computeStringValue = opts.computeStringValue;

    if (typeof opts.presentationType !== "undefined") {
      this.presentationProxy = CrudProperty.findOrCreateInstance({
        type: opts.presentationType
      });
    }
  }

  public get stringValue(): string {
    if (this.computeStringValue) return this.computeStringValue(this);

    return super.stringValue;
  }

  public get isHydrated() {
    return true;
  }

  public get isReadonly() {
    return true;
  }

  public isReadonlyToUser() {
    return true;
  }

  public get value() {
    return this.computeValue(this);
  }

  public set(value: any, skipMarkingAsUnsaved = false) {
    // nothin
  }

  public get(opts?: CrudPropertyGet) {
    if (!this.presentationProxy) return super.get(opts);

    this.presentationProxy.set(this.value);
    return this.presentationProxy.get(opts);
  }

  // don't serialize computed properties
  public get serializedValue() {
    return undefined;
  }

  public get hasUnsavedChanges() {
    return false;
  }
}
