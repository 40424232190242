import { ICrudField } from "../CrudField";
import { CrudModel } from "../CrudModel";
import { StringField } from "./StringField";

export interface IWysiwygField extends ICrudField {
  extensions?: string[];
}

export class WysiwygField extends StringField {
  public static fieldName = "WysiwygField";
  public static formComponentName: string = "form-wysiwyg-field";
  public static viewComponentName: string = "view-wysiwyg-field";
  protected static requiresDetachedLabel: boolean = true;

  public extensions: string[] = [
    "Headings",
    "Bold",
    "Italic",
    "Strike",
    "Underline",
    "Code",
    "Paragraph",
    "BulletList",
    "OrderedList",
    "ListItem",
    "Link",
    "Blockquote",
    "HardBreak",
    "HorizontalRule",
    "History",
  ];

  constructor(opts: IWysiwygField, model: CrudModel) {
    super(opts, model);

    if (typeof opts.extensions !== "undefined")
      this.extensions = opts.extensions;

    // defaults
    if (typeof opts.fieldWidth === "undefined") this.fieldWidth = "full";

    // if required, use htmlRequired instead on property
    if (this.rules.includes("required")) {
      this._rules.push("htmlRequired");
    }
  }
}
