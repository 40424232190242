export const state = () => ({
  title: ""
});

export const getters = {
  title: state => {
    return state.title;
  }
};

export const mutations = {
  set_title(store, data) {
    if (!data) data = "";
    store.title = data;
  }
};
