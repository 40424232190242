
import { RelationshipField } from "../../field-types/RelationshipField";

export default {
  data() {
    return {
      modalOpen: null
    };
  },
  props: {
    field: {
      type: RelationshipField,
      required: true
    },
    isReadMode: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openModal() {
      if (this.isNew) {
        this.field.createNew();
      }
      this.modalOpen = true;
    },
    cancelNew() {
      this.field.property.clear();
    }
  },
  computed: {
    isNew() {
      return this.field.property.isEmpty;
    },
    showButton() {
      return (
        !this.isReadMode &&
        !this.field.isReadonlyToUser() &&
        !this.field.property.isEmpty
      );
    },
    icon() {
      if (this.isNew) {
        return "mdi-plus-box-outline";
      }
      return "mdi-dock-window";
    }
  }
};
