import {
  CrudField,
  CrudFieldQuery,
  ICrudField,
  ICrudFieldDefinition
} from "../CrudField";
import { CrudModel } from "../CrudModel";
import { CrudPropertyGet } from "../CrudProperty";

export interface IFieldGroupField extends ICrudField {
  subFields?: ICrudFieldDefinition[];
  stringValueExcludedSubfields?: string[];
  seamless?: boolean;
}
export class FieldGroupField extends CrudField {
  public static fieldName = "FieldGroupField";
  public static formComponentName: string = "form-field-group-field";
  public static viewComponentName: string = "view-field-group-field";

  protected static requiresDetachedLabel: boolean = true;

  protected subFieldDefs: ICrudFieldDefinition[] = [];
  protected stringValueExcludedSubfields: string[] = [];

  protected _subFields: CrudField[] = [];
  public get subFields() {
    if (this._subFields.length === 0) {
      this._subFields = CrudField.newInstances(this.subFieldDefs, this.model);
    }
    return this._subFields;
  }

  protected get _classRef(): typeof FieldGroupField {
    return Object.getPrototypeOf(this).constructor;
  }

  public seamless = false;

  constructor(opts: IFieldGroupField, model: CrudModel) {
    super(opts, model);

    if (typeof opts.subFields !== "undefined")
      this.subFieldDefs = opts.subFields;

    if (typeof opts.fieldWidth === "undefined") this.fieldWidth = "large";

    if (typeof opts.seamless !== "undefined") this.seamless = opts.seamless;

    if (typeof opts.stringValueExcludedSubfields !== "undefined")
      this.stringValueExcludedSubfields = opts.stringValueExcludedSubfields;
  }

  public findFields(
    fieldQuery?: CrudFieldQuery[] | CrudFieldQuery
  ): CrudField[] | undefined {
    if (!fieldQuery) return [this];

    if (!Array.isArray(fieldQuery)) fieldQuery = [fieldQuery];

    if (super.findFields(fieldQuery)) return [this];

    // console.log(
    //   fieldQuery,
    //   this.subFields,
    //   this.subFields.map(field => field.findFields(fieldQuery)).flat()
    // );

    return this.subFields
      .map(field => field.findFields(fieldQuery))
      .flat()
      .filter(field => field) as CrudField[];
  }

  public getRelativeLabel(fieldQuery: CrudFieldQuery, prefix = "") {
    const fields = this.findFields(fieldQuery);

    if (!fields || fields.length === 0) return "";
    if (fields.length > 1)
      console.error(
        `FieldGroupField.getRelativeLabel() returned more than 1 result.
        Check for duplicated property names or use a more specific query.`,
        fieldQuery,
        fields
      );

    const field = fields.pop() as CrudField;
    const thisLabel = super.getRelativeLabel(fieldQuery, prefix);
    if (field === this) return thisLabel;

    return field.getRelativeLabel(fieldQuery, thisLabel);
  }

  public get isOrphan() {
    return this.subFields.every(field => field.isOrphan);
  }

  public get value() {
    return this.get();
  }

  public get isEmpty() {
    return this.subFields.every(field => field.isEmpty);
  }

  public get serializedPayload() {
    return this.subFields
      .map(field => field.serializedPayload)
      .reduce((payloads, payload) => ({ ...payloads, ...payload }), {});
  }

  public get(opts?: CrudPropertyGet) {
    const val = this.subFields
      .filter(
        field =>
          !this.stringValueExcludedSubfields.includes(field.id) &&
          field.isEnabled &&
          !field.isEmpty
      )
      .map(field => field.get(opts))
      .join(" ");

    return val;
  }
}
