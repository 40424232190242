import { CrudModel } from "../CrudModel";
import { CrudProperty } from "../CrudProperty";

export class JsonProperty extends CrudProperty {
  protected _value: Record<string, any> | null = null;

  public get serializedValue() {
    return JSON.stringify(this.value);
  }

  public coerceValue(val: unknown): any {
    if (val === null) return null;

    if (typeof val === "string") {
      try {
        val = JSON.parse(val);
      } catch (e) {
        console.error("JsonProperty: could not parse JSON string", val);
        val = {};
      }
    }

    return super.coerceValue(val);
  }
}
