
import { DateField } from "../../field-types/DateField";
import * as dayjs from "dayjs";

export default {
  data() {
    return {
      menu: false,
      value: null,
      pickerValue: null,
      activePicker: null,
      onDatePicker: false,
    };
  },
  props: {
    field: {
      type: DateField,
    },
    inputProps: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    updateField() {
      this.field.set(this.pickerValue);
      this.$emit("change", this.field);
      this.updateComponentValue();

      // delayed validation
      setTimeout(() => {
        this.$refs.form.validate();
      }, 100);
    },
    updateComponentValue() {
      const dateVal = this.field.get(true);
      this.value = dateVal ? this.field.get() : null;
      this.pickerValue = dateVal ? dayjs(dateVal).format("YYYY-MM-DD") : null;
    },
    updateFromTextfield() {
      const textfieldDate = dayjs(this.value, "M/D/YYYY");
      const newPickerValue =
        this.value && textfieldDate.isValid
          ? textfieldDate.format("YYYY-MM-DD")
          : null;
      this.pickerValue = newPickerValue;
      this.updateField();
    },
  },
  watch: {
    "field.value": {
      immediate: true,
      handler: function () {
        this.updateComponentValue();
      },
    },
    activePicker() {
      /*
      we delay it because the content-click event will see "DATE" as the active
      picker when they've clicked on a month in the month picker (beacuse this takes
      them to the DATE screen). We want to make sure we only see "DATE" in the
      content-click event when clicking on a date
      */
      if (this.activePicker == "DATE")
        setTimeout(() => (this.onDatePicker = true), 100);
      else this.onDatePicker = false;
    },
  },
};
