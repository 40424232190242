import { EmailProperty } from "../property-types/EmailProperty";
import { StringField } from "./StringField";

export class EmailField extends StringField {
  public static fieldName = "EmailField";
  public static viewComponentName: string = "view-email-field";

  protected static icon = "mdi-email";
  protected static readonly defaultPropertyType = EmailProperty;
}
