
import { CrudLayout } from "../../CrudLayout";

export default {
  props: {
    layout: {
      type: CrudLayout,
      required: true,
    },
  },
  data() {
    return {
      tabs: null,
    };
  },
  computed: {
    hasIcons() {
      return this.layout.tabs.some((tab) => tab.icon);
    },
  },
};
