import { CrudModel } from "../CrudModel";
import { AddressField, IAddressField } from "./AddressField";

export class GoogleAddressField extends AddressField {
  public static fieldName = "GoogleAddressField";
  public static formComponentName: string = "form-google-address-field";

  constructor(opts: IAddressField, model: CrudModel) {
    super(opts, model);

    // defaults
    if (typeof opts.fieldWidth === "undefined") this.fieldWidth = "large";
  }
}
