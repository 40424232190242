import { CrudModel } from "../CrudModel";
import { ICrudField } from "../CrudField";
import { NumberField } from "./NumberField";

export interface IRatingField extends ICrudField {
  maxRating?: number;
  minRating?: number;
}
export class RatingField extends NumberField {
  public static fieldName = "RatingField";

  protected static decoratorSuffix = "/";
  public maxRating = 5;
  public minRating = 1;

  constructor(opts: IRatingField, model: CrudModel) {
    super(opts, model);

    if (typeof opts.minRating !== "undefined") this.minRating = opts.minRating;
    if (typeof opts.maxRating !== "undefined") this.maxRating = opts.maxRating;
  }
}
