import { CrudField } from "../CrudField";
import { CrudProperty } from "../CrudProperty";

const DynamicFilterOperators = {
  equals: "is exactly",
  notEquals: "is not",
  contains: "contains",
  notContains: "doesn't contain",
  greaterThan: "is greater than",
  lessThan: "is less than",
  // isNull: "is blank",
};

export type DynamicFilterOperator = keyof typeof DynamicFilterOperators;

export interface DynamicFilterOpts {
  field: CrudField;
  id: number;
  serializedName?: string;
  operator?: DynamicFilterOperator;
  defaultOperator?: DynamicFilterOperator;
  value?: any;
  label?: string;
}

export class DynamicFilter {
  public id: number;
  public field: CrudField;
  public property: CrudProperty;
  public operator: DynamicFilterOperator;
  public operatorLabelsMap: object = {};
  public disabledOperators: DynamicFilterOperator[] = [];
  public defaultOperator: DynamicFilterOperator;

  constructor(opts: DynamicFilterOpts) {
    this.id = opts.id;

    this.field = opts.field.clone({
      isOrphan: true,
      disableRules: ["required"],
    });

    this.property = this.field.property;

    if (typeof opts.value !== "undefined") {
      this.property.set(opts.value);
    }

    if (typeof opts.defaultOperator !== "undefined") {
      this.defaultOperator = opts.defaultOperator;
    } else {
      this.defaultOperator = this.operators[0].key as DynamicFilterOperator;
    }

    if (typeof opts.serializedName !== "undefined") {
      this._serializedName = opts.serializedName;
    }

    if (typeof opts.label !== "undefined") {
      this._label = opts.label;
    }

    if (typeof opts.operator !== "undefined") {
      this.operator = opts.operator;
    } else {
      this.operator = this.defaultOperator;
    }
  }

  public get serializedValue() {
    console.log(this.operator, this.property.isEmpty); // TODO: removing this breaks reactivity?
    if (!this.operator || this.property.isEmpty) return undefined;

    return {
      p: this.serializedName,
      o: this.operator,
      v: this.property.serializedValue,
    };
  }

  private _serializedName?: string;
  public get serializedName() {
    return this._serializedName
      ? this._serializedName
      : this.property.serializedName;
  }

  public get operatorsMap() {
    return {
      ...DynamicFilterOperators,
      ...this.operatorLabelsMap,
    };
  }

  public get operators() {
    return Object.keys(this.operatorsMap)
      .filter(
        (key: string) =>
          !this.disabledOperators.includes(key as DynamicFilterOperator)
      )
      .map((key: string) => ({
        key,
        label: this.operatorsMap[key as DynamicFilterOperator],
      }));
  }

  public setOperator(operator: DynamicFilterOperator) {
    this.operator = operator;
  }

  public get operatorLabel() {
    return this.operator ? this.operatorsMap[this.operator] : "";
  }

  public get value() {
    return this.field.isEmpty ? undefined : this.field.stringValue;
  }

  public getValueAsync(): Promise<any> {
    return Promise.resolve(this.value);
  }

  public get isSet() {
    return this.operator && this.value !== undefined;
  }

  protected _label?: string;
  public get label() {
    if (this._label) return this._label;
    return this.field.label;
  }

  public getUiField() {
    return this.field.clone({ isOrphan: true });
  }
}
