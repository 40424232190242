
import { StringField } from "../../field-types/StringField";

export default {
  props: {
    field: {
      type: StringField,
      required: true,
    },
    inputProps: {
      type: Object,
      default: () => ({}),
    },
  },
};
