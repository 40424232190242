
import _ from "lodash";
import { CrudCollection } from "../../CrudCollection";
import { RelationshipFieldMany } from "../../field-types/RelationshipFieldMany";
import { CrudSearchFilter } from "../../filters/CrudSearchFilter";

export default {
  data() {
    return {
      search: null,
      loading: false,
      isUntouched: true,
      autocompleteItems: [],
      value: null,
      searchFilter: CrudSearchFilter.create(),
      updateSearchResults: _.debounce(function () {
        this.searchFilter.setQuery(this.search);
        this.querySelections(this.search);
      }, 250),
    };
  },
  props: {
    field: {
      type: RelationshipFieldMany,
      required: true,
    },
    allowCreateNew: {
      type: Boolean,
      default: true,
    },
    inputProps: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    setFieldInitalValue() {
      const propVal = this.property.get(true);
      if (!propVal) {
        this.value = [
          {
            text: null,
            value: null,
          },
        ];
        return;
      }

      const propValues = this.property.collection.instances.map((model) => {
        return {
          text: model.label,
          value: model.id,
          model: model,
        };
      });

      // set default
      this.value = propValues;

      if (!this.asSelect && this.autocompleteItems.length == 0)
        this.autocompleteItems = propValues;
    },
    querySelections() {
      if (this.asSelect) return;

      this.loading = true;
      this.autocompleteCollection
        .fetch()
        .then(() => {
          this.setAutocompleteItems(this.autocompleteCollection.instances);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearValue() {
      this.value = null;
      this.isUntouched = false;
    },
    decorateInstances(instances) {
      return instances.map((instance) => {
        return {
          text: instance.label,
          value: instance.id,
          model: instance,
        };
      });
    },
    createNew() {
      const newModel = new this.property.relatedModel();
      newModel.setLabel(this.search);

      newModel.save().then((model) => {
        this.property.set(model);
        this.setFieldInitalValue();
      });
    },
    setAutocompleteItems() {
      const newItems = this.decorateInstances(
        this.autocompleteCollection.instances
      );
      this.autocompleteItems = [
        ...newItems,
        ...(this.value
          ? this.value.filter((item) => {
              return !newItems.some((newItem) => newItem.value == item.value);
            })
          : []),
      ];
    },
  },
  computed: {
    noDataText: function () {
      return this.isUntouched ? "Type to search" : "No results found.";
    },
    property() {
      return this.field.property;
    },
    autocompleteCollection() {
      const collectionLayoutOptsFilters =
        this.field.collectionLayoutOpts &&
        this.field.collectionLayoutOpts.filters
          ? this.field.collectionLayoutOpts.filters
          : [];

      return new CrudCollection({
        model: this.property.relatedModel,
        remoteQueryFilters: [this.searchFilter, ...collectionLayoutOptsFilters],
      });
    },
    // loading() {
    //   return (
    //     this.autocompleteCollection.loading || this.field.collection.loading
    //   );
    // },
    asSelect() {
      return this.field.property.relatedModel.api.fullCache;
    },
    showCreateNew() {
      return false; // disabled for now

      if (
        !this.allowCreateNew ||
        !this.search ||
        this.isUntouched ||
        this.field.isReadonlyToUser()
      )
        return false;

      // make sure the search term is not in the results
      return !this.autocompleteItems.some(
        (item) =>
          item.text.toLowerCase().trim() == this.search.toLowerCase().trim()
      );
    },
  },
  watch: {
    value: {
      handler: function () {
        if (this.isUntouched) return;

        const newValIds = !this.value
          ? ""
          : this.value.map((val) => val.value).join(",");

        const oldValIds = this.property.get(true).join(",");

        if (!_.isEqual(newValIds, oldValIds)) {
          if (newValIds.length === 0) {
            this.property.set(null);
          } else {
            // console.log("setting property", oldValIds, newValIds);
            this.property.set(
              this.value.map((val) => (val.model ? val.model : val.value))
            );
          }
        }
        this.$emit("change");
      },
    },
    "property.collection.instances": {
      immediate: true,
      handler() {
        // maybe dont need autocomplete
        if (this.asSelect && this.autocompleteItems.length === 0) {
          this.field.property.relatedModel
            .getAll()
            .then(
              (instances) =>
                (this.autocompleteItems = this.decorateInstances(instances))
            );
        }

        this.field.property.hydrate().then(() => {
          if (this.property.collection.instances.length > 0)
            this.setFieldInitalValue();
          else this.value = null;
        });
      },
    },
    search() {
      if (!this.search) return;
      this.updateSearchResults();
    },
  },
};
