import _ from "lodash";

export type RuleValidator = (value: any) => boolean;
export type IVuetifyValidatorResult = boolean | string;
export type IVuetifyValidator = (value: any) => IVuetifyValidatorResult;
export type IUseRule = Rule | IVuetifyValidator | string;

export interface IRule {
  validator: RuleValidator;
  message?: string | Function;
  name?: string;
}

export default class Rule {
  private static _rules: Record<string, IRule> = {};
  public static getRule(rule: string | Rule): Rule {
    if (typeof rule !== "string") return rule as Rule;

    if (!this._rules[rule]) {
      console.error(this._rules);
      throw new Error("Rule not registered: " + rule);
    }

    return this._rules[rule] as Rule;
  }

  public static rulesIncludes(rules: IUseRule[], rule: string | Rule): boolean {
    const ruleName = typeof rule === "string" ? rule : rule.name;
    return rules.find(rulesetItem =>
      typeof rulesetItem === "string"
        ? rulesetItem === ruleName
        : rulesetItem.name === ruleName
    )
      ? true
      : false;
  }

  public static registerRule(name: string, rule: IRule) {
    if (this._rules[name]) {
      console.error(this._rules[name]);
      throw new Error("Rule name already exists: " + name);
    }

    if (_.isPlainObject(rule)) rule = new Rule(rule);
    this._rules[name] = rule;
  }
  public static registerRules(registrationMap: Record<string, IRule> = {}) {
    Object.keys(registrationMap).forEach((name: string) =>
      this.registerRule(name, registrationMap[name])
    );
  }

  public validator: RuleValidator = function(value) {
    return true;
  };
  protected _invalidMessage: string | Function = "This field is invalid";
  public getInvalidMessage(value?: any): string {
    if (typeof this._invalidMessage === "function")
      return this._invalidMessage(value);
    return this._invalidMessage;
  }

  public name: string = "";
  constructor(opts: IRule) {
    this.validator = opts.validator;

    if (opts.name) this.name = opts.name;
    if (opts.message) this._invalidMessage = opts.message;
  }

  public getVuetifyRule(): Function {
    return (value): IVuetifyValidatorResult => {
      if (this.validator(value)) return true;
      return this.getInvalidMessage(value);
    };
  }

  public static isValid(rules: IUseRule[], value: any) {
    return rules
      .map(useRule => this._getRuleFromUseRule(useRule))
      .every(rule => rule.validator(value));
  }

  public static invalidMessages(rules: IUseRule[], value: any) {
    return rules
      .map(useRule => this._getRuleFromUseRule(useRule))
      .filter(rule => !rule.validator(value))
      .map(rule => rule.getInvalidMessage(value));
  }

  public static getVuetifyRules(rules: IUseRule[]) {
    return rules.map(useRule =>
      this._getRuleFromUseRule(useRule).getVuetifyRule()
    );
  }

  private static _getRuleFromUseRule(useRule: IUseRule): Rule {
    if (typeof useRule === "string") {
      return this.getRule(useRule);
    }

    if (typeof useRule === "function") {
      return new Rule({ validator: useRule as RuleValidator });
    }

    return useRule;
  }
}
