
import { CrudModel } from "../CrudModel";

export default {
  data() {
    return {};
  },
  props: {
    model: {
      type: CrudModel,
      required: true,
    },
    layoutId: {
      type: String,
      default: "",
    },
    showModelActions: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    saved() {
      this.$emit("expansion-saved");
    },
  },
  computed: {
    layout() {
      return this.model.getModelLayout(this.layoutId);
    },
    canShowModelActions() {
      return (
        this.showModelActions &&
        !this.layout.readMode &&
        !this.model.isReadonlyToUser()
      );
    },
  },
  watch: {
    "model.isSaving": {
      immediate: true,
      handler() {
        if (this.model.isSaving) this.$emit("expansion-saving");
      },
    },
  },
};
