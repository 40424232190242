
import { AddressFieldsEnum } from "../../field-types/AddressField";

export default {
  data() {
    return {
      value: null,
      streetField: null,
      cityField: null,
      division1Field: null,
      division2Field: null,
      postalField: null,
      countryField: null
    };
  },
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  computed: {
    subFields() {
      return [
        this.streetField,
        this.street2Field,
        this.cityField,
        this.division1Field,
        this.division2Field,
        this.postalField,
        this.countryField
      ];
    },
    textLines() {
      const streetLines = [this.streetField, this.street2Field]
        .filter(subField => subField && subField.get(true))
        .map(subField => subField.get());

      const cityDivisionsLine = [
        this.cityField,
        this.division1Field,
        this.division2Field
      ]
        .filter(subField => subField && subField.get(true))
        .map(subField => subField.get())
        .join(", ");

      const cityDivisionsPostalLine =
        cityDivisionsLine +
        (this.postalField && this.postalField.get(true)
          ? " " + this.postalField.get()
          : "");

      const countryLine =
        this.countryField && this.countryField.get(true)
          ? this.countryField.get()
          : "";

      return [
        ...streetLines,
        cityDivisionsPostalLine,
        countryLine
      ].filter(lineVal => lineVal.trim());
    },
    googleMapsUrl() {
      const addressQuery = encodeURIComponent(
        this.textLines.join(" ").replaceAll(" ", "+")
      );
      return `https://www.google.com/maps/search/${addressQuery}/`;
    },
    valueIsNone() {
      return !this.value && this.value !== 0;
    }
  },
  watch: {
    field: {
      immediate: true,
      handler() {
        this.streetField = this.field.getSubField(AddressFieldsEnum.STREET_1);
        this.street2Field = this.field.getSubField(AddressFieldsEnum.STREET_2);
        this.cityField = this.field.getSubField(AddressFieldsEnum.CITY);
        this.division1Field = this.field.getSubField(
          AddressFieldsEnum.DIVISION_1
        );
        this.division2Field = this.field.getSubField(
          AddressFieldsEnum.DIVISION_2
        );
        this.postalField = this.field.getSubField(
          AddressFieldsEnum.POSTAL_CODE
        );
        this.countryField = this.field.getSubField(AddressFieldsEnum.COUNTRY);
      }
    }
  }
};
