
import { CrudField } from "../../CrudField";

export default {
  data() {
    return {
      value: null,
      rawValue: null
    };
  },
  props: {
    field: {
      type: CrudField,
      required: true
    }
  },
  watch: {
    "field.value": {
      immediate: true,
      handler() {
        this.value = this.field.get();
        this.rawValue = this.field.get(true);
      }
    }
  }
};
