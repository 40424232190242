
import { CrudField } from "../../CrudField";

export default {
  data() {
    return {
      value: null,
    };
  },
  props: {
    field: {
      type: CrudField,
      required: true,
    },
  },
  computed: {
    relationLink() {
      return this.field.property.modelInstance.getRouteSingle();
    },
    clickable() {
      return (
        !this.field.property.isEmpty &&
        this.relationLink &&
        !this.field.property.modelInstance.isReadonlyToUser()
      );
    },
    noneText() {
      return this.$config.readonlyNoneDisplay;
    },
  },
  watch: {
    "field.property.value": {
      immediate: true,
      handler: function () {
        if (this.field.property.isEmpty) {
          this.value = null;
          return;
        }

        this.field.property.hydrate().then(() => {
          this.value = this.field.instanceLabel;
        });
      },
    },
  },
};
