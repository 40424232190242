
import { SelectField } from "../../field-types/SelectField";

export default {
  data() {
    return {
      value: null
    };
  },
  props: {
    field: {
      type: SelectField,
      required: true
    },
    inputProps: {
      type: Object,
      default: () => ({})
    }
  }
};
