
import { CrudModel } from "../../CrudModel";

export default {
  props: {
    model: {
      type: CrudModel,
      required: true
    },
    saveRequestOptions: {
      type: Object,
      default: () => ({})
    },
    requiresValidation: {
      type: Boolean,
      default: false
    },
    requiresUnsavedChanges: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonDisabled() {
      if (this.requiresValidation && !this.model.isValid) {
        return true;
      }

      if (this.requiresUnsavedChanges && !this.model.hasUnsavedChanges) {
        return true;
      }

      return false;
    },
    tooltipDisabled() {
      return !this.buttonDisabled;
    },
    tooltipMessage() {
      return !this.model.hasUnsavedChanges
        ? "No changes to save"
        : "These fields are invalid:";
    },
    tooltipColor() {
      return !this.model.hasUnsavedChanges ? "grey darken-2" : "error";
    },
    iconOverride() {
      if (!this.model.isValid) return "mdi-alert-octagon-outline";
    }
  }
};
