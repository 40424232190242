
export default {
  data() {
    return {};
  },
  props: {
    itemLabel: {
      type: String,
      default: ""
    },
    open: {
      type: Boolean,
      default: false
    }
  }
};
