import _ from "lodash";
import {
  CrudField,
  CrudFieldType,
  ICrudField,
  ICrudFieldDefinition
} from "../CrudField";
import { CrudModel } from "../CrudModel";
import { FieldGroupField, IFieldGroupField } from "./FieldGroupField";

export interface ISubFieldDefinition extends ICrudFieldDefinition {
  subFieldId: any;
  type: any;
  opts: ICrudField;
}
export type TSubFieldOpts = Record<any, ICrudField>;
export type TSubFieldType = Record<any, CrudFieldType>;
export interface IStructuredFieldGroupField extends IFieldGroupField {
  subFieldOpts?: TSubFieldOpts;
  subFieldTypes?: TSubFieldType;
  disabledSubFields?: any[];
}
export class StructuredFieldGroupField extends FieldGroupField {
  public static fieldName = "StructuredFieldGroupField";
  protected static structuredSubFieldDefs: ISubFieldDefinition[] = [];
  protected structuredSubFieldDefs: ISubFieldDefinition[] = [];

  public get subFields() {
    if (this._subFields.length === 0) {
      this._subFields = CrudField.newInstances(
        this.structuredSubFieldDefs,
        this.model
      );
    }
    return this._subFields;
  }

  constructor(opts: IStructuredFieldGroupField, model: CrudModel) {
    super(opts, model);

    if (this._subFields.length > 0) {
      console.warn(
        "StrucutredFieldGroupFields should not have subfields defined. They will be ignored."
      );
      this._subFields = [];
    }

    this.structuredSubFieldDefs = _.cloneDeep(
      Object.getPrototypeOf(this).constructor.structuredSubFieldDefs
    );

    if (typeof opts.disabledSubFields !== "undefined")
      this._disableSubFields(opts.disabledSubFields);

    if (typeof opts.subFieldTypes !== "undefined") {
      this._applySubFieldTypes(opts.subFieldTypes);
    }

    if (typeof opts.subFieldOpts !== "undefined") {
      this._applySubFieldOpts(opts.subFieldOpts);
    }
  }

  protected _disableSubFields(subFieldIds: any[]) {
    subFieldIds.forEach(subFieldId => {
      const subFieldDefInd = this.structuredSubFieldDefs.findIndex(
        def => def.subFieldId == subFieldId
      );

      if (subFieldDefInd === -1)
        return console.error(
          `Can't disable field. SubField ${subFieldId} not found in FieldGroupField: `,
          this
        );

      this.structuredSubFieldDefs.splice(subFieldDefInd, 1);
    });
  }

  protected _applySubFieldOpts(subFieldOpts: TSubFieldOpts) {
    Object.keys(subFieldOpts).forEach(subFieldId => {
      const subFieldDefIndex = this.structuredSubFieldDefs.findIndex(
        def => def.subFieldId == subFieldId
      );
      if (subFieldDefIndex === -1)
        return console.error(
          `Unable to apply SubField opts. ${subFieldId} not found in FieldGroupField: `,
          this
        );

      const instanceSubfieldOpts = subFieldOpts[subFieldId];

      // create a unique id for the subfield if not defined explicitly
      if (typeof instanceSubfieldOpts.id === "undefined") {
        const optBaseId =
          typeof this.structuredSubFieldDefs[subFieldDefIndex].opts.id ===
          "undefined"
            ? this.structuredSubFieldDefs[subFieldDefIndex].opts.property
            : this.structuredSubFieldDefs[subFieldDefIndex].opts.id;

        instanceSubfieldOpts.id = this.id + "_" + optBaseId;
      }

      this.structuredSubFieldDefs[subFieldDefIndex].opts = Object.assign(
        {},
        this.structuredSubFieldDefs[subFieldDefIndex].opts,
        instanceSubfieldOpts
      );
    });
  }

  protected _applySubFieldTypes(subFieldTypes: TSubFieldType) {
    Object.keys(subFieldTypes).forEach(subFieldId => {
      const subFieldDefIndex = this.structuredSubFieldDefs.findIndex(
        def => def.subFieldId == subFieldId
      );
      if (subFieldDefIndex === -1)
        return console.error(
          `Unable to apply SubField opts. ${subFieldId} not found in FieldGroupField: `,
          this
        );

      this.structuredSubFieldDefs[subFieldDefIndex].type =
        subFieldTypes[subFieldId];
    });
  }

  public getSubFieldByStructuredId(structuredId) {
    const subFieldDef = this.structuredSubFieldDefs.find(def => {
      return def.subFieldId == structuredId;
    });

    if (!subFieldDef) return null;

    return subFieldDef.opts.id;
  }

  public getSubField(structuredId: any) {
    const subFieldId = this.getSubFieldByStructuredId(structuredId);

    if (!subFieldId) return null;

    return this._subFields.find(subField => subField.id === subFieldId);
  }
}
