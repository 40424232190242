import { CrudModel } from "../CrudModel";
import { CrudField, ICrudField } from "../CrudField";
import { StringProperty } from "../property-types/StringProperty";

export interface IStringField extends ICrudField {
  maxLength?: number;
  linkHref?: string;
}
export class StringField extends CrudField {
  public static fieldName = "StringField";
  public static formComponentName: string = "form-string-field";
  protected static readonly defaultPropertyType = StringProperty;

  public maxLength: number = 0;
  public linkHref: string = "";

  constructor(opts: IStringField, model?: CrudModel) {
    super(opts, model);

    if (typeof opts.maxLength !== "undefined") this.maxLength = opts.maxLength;

    if (typeof opts.linkHref !== "undefined") this.linkHref = opts.linkHref;
  }

  public get formComponentProps() {
    const props = super.formComponentProps;

    if (this.maxLength || (this.property as StringProperty).maxLength) {
      props.maxlength = Math.max(
        this.maxLength,
        (this.property as StringProperty).maxLength
      );

      props.counter = true;
    }

    return props;
  }
}
