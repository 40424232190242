export interface CrudLayoutDefinition extends Record<string, any> {
  id?: string;
  componentName?: string;
  componentProps?: Record<string, any>;
}

export interface CrudLayoutOpts extends CrudLayoutDefinition {}

export type CrudLayoutType = typeof CrudLayout;

export type ICrudLayoutType = CrudLayoutType | string;

export interface TypedCrudLayoutDefinition {
  type: ICrudLayoutType;
  opts: CrudLayoutOpts;
}

export class CrudLayout {
  private static _layoutTypes: Record<string, ICrudLayoutType> = {};
  public static getCrudLayoutType(type: ICrudLayoutType): CrudLayoutType {
    if (typeof type !== "string") return type as CrudLayoutType;
    if (!this._layoutTypes[type])
      throw new Error("CrudLayoutType not registered: " + type);

    return this._layoutTypes[type] as CrudLayoutType;
  }
  public static registerCrudLayoutType(name: string, type: ICrudLayoutType) {
    this._layoutTypes[name] = type;
  }
  public static registerCrudLayoutTypes(
    registrationMap: Record<string, ICrudLayoutType> = {}
  ) {
    Object.keys(registrationMap).forEach((name: string) =>
      this.registerCrudLayoutType(name, registrationMap[name] as CrudLayoutType)
    );
  }

  // factory method
  public static newInstance(
    def: TypedCrudLayoutDefinition,
    additionalOptions?: {}
  ): CrudLayout {
    const layoutType = this.getCrudLayoutType(def.type);
    return new layoutType({
      ...def.opts,
      ...additionalOptions,
    });
  }
  public static newInstances(
    defs: TypedCrudLayoutDefinition[] = [],
    additionalOptions: {}
  ): CrudLayout[] {
    return defs.map((layoutDef) =>
      this.newInstance(layoutDef, additionalOptions)
    );
  }

  protected get _classRef(): typeof CrudLayout {
    return Object.getPrototypeOf(this).constructor;
  }

  protected static $nuxt;
  public static setNuxtContext(context) {
    this.$nuxt = context;
  }
  protected get $nuxt() {
    return this._classRef.$nuxt;
  }

  public id: string;

  protected _componentName: string = "";
  public static componentName: string = "";
  public get componentName(): string {
    return this._componentName
      ? this._componentName
      : this._classRef.componentName;
  }

  protected _componentProps: Record<string, any> = {};
  public static componentProps: Record<string, any> = {};

  protected _opts: CrudLayoutOpts;

  constructor(opts: CrudLayoutOpts) {
    this._opts = opts;

    if (typeof opts.id !== "undefined") this.id = opts.id;
    else this.id = "anonymous_" + Math.random().toString(36).substr(2, 9);

    if (typeof opts.componentName !== "undefined")
      this._componentName = opts.componentName;

    if (typeof opts.componentProps !== "undefined")
      this._componentProps = opts.componentProps;
  }

  public get componentProps(): Record<string, any> {
    return Object.assign(
      {},
      {
        layout: this,
      },
      this._classRef.componentProps,
      this._componentProps
    );
  }
}
