import { CrudModel } from "../CrudModel";
import { CrudProperty, CrudPropertyQuery } from "../CrudProperty";
import {
  CompareConditional,
  HasCompareConditional,
  ICompareConditional
} from "./CompareConditional";

export interface IAttributeConditional extends ICompareConditional {
  attribute: string;
  on?: "model" | "property";
  property?: CrudPropertyQuery;
}

export interface HasAttributeConditional extends HasCompareConditional {
  model: CrudModel;
  property?: CrudProperty;
}

export class AttributeConditional extends CompareConditional {
  protected attribute: string;
  protected on: "model" | "property" = "model";
  protected property: CrudProperty | undefined;

  constructor(opts: IAttributeConditional, owner: HasAttributeConditional) {
    super(opts, owner);

    this.attribute = opts.attribute;

    if (opts.on) this.on = opts.on;

    if (opts.property) {
      this.property = CrudProperty.findOrCreateInstance({
        model: owner.model,
        prop: opts.property
      });

      if (!this.property) {
        console.error(this);
        throw new Error("Unable to resolve property: " + this.property);
      }
    }
  }

  protected get target(): CrudModel | CrudProperty {
    if (this.on === "model")
      return (this.owner as HasAttributeConditional).model;
    else {
      if (!this.property) {
        console.error(this);
        throw new Error("Unable to resolve property: " + this.property);
      }

      return this.property;
    }
  }

  protected get compare() {
    console.log(this.target[this.attribute]);
    return this.target[this.attribute];
  }
}
