import { DynamicFilter, DynamicFilterOperator } from "../DynamicFilter";

export class SelectFilter extends DynamicFilter {
  public disabledOperators: DynamicFilterOperator[] = [
    "contains",
    "notContains",
    "lessThan",
    "greaterThan",
  ];
}
