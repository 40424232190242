import { CrudModel, CrudModelType, ICrudModelType } from "../CrudModel";
import { CrudField, CrudFieldQuery, ICrudField } from "../CrudField";
import { RelationshipProperty } from "../property-types/RelationshipProperty";
import { IRelationshipField, RelationshipField } from "./RelationshipField";

export interface IModelLayoutField extends IRelationshipField {
  layoutId?: string;
  seamless?: boolean;
  collapseView?: boolean;
}
export class ModelLayoutField extends RelationshipField {
  public static fieldName = "ModelLayoutField";
  public static formComponentName: string = "form-model-layout-field";
  protected static readonly defaultPropertyType = RelationshipProperty;

  protected static requiresDetachedLabel = true;

  public layoutId = "";
  public get layout() {
    return this.modelInstance.getModelLayout(this.layoutId);
  }

  public seamless = true;
  public collapseView = true;

  public get viewComponentName() {
    return this._viewComponentName
      ? this._viewComponentName
      : this.collapseView
      ? "view-relationship-field"
      : "form-model-layout-field";
  }

  constructor(opts: IModelLayoutField, model: CrudModel) {
    super(opts, model);

    if (typeof opts.layoutId !== "undefined") this.layoutId = opts.layoutId;

    if (typeof opts.collapseView !== "undefined")
      this.collapseView = opts.collapseView;

    if (typeof opts.seamless !== "undefined") {
      this.seamless = opts.seamless;

      if (typeof opts.cols === "undefined") this.cols = 12;
    }

    this._formComponentProps = {
      ...this._formComponentProps,

      layoutId: this.layoutId,
      seamless: this.seamless
    };
  }
}
