
import { CrudModel } from "../../CrudModel";

export default {
  props: {
    model: {
      type: CrudModel,
      required: true
    }
  },
  computed: {
    visible() {
      return this.model.hasUnsavedChanges && !this.model.isValid;
    }
  }
};
