export default (context, inject) => {
  const renderHelpers = {
    money(val) {
      return "$" + parseFloat(val).toFixed(2);
    },
    uploadUrl(path) {
      return context.$config.uploadsURL + "/" + path;
    }
  };
  inject("renderHelpers", renderHelpers);
};
