
import { CrudModel } from "~/plugins/Cruxtify/CrudModel";
export default {
  name: "DuplicateModel",
  components: {},
  props: {
    model: {
      type: CrudModel,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    duplicate() {
      const defaultVals = Object.keys(this.model.serializedPayload)
        .filter((key) => {
          const prop = this.model.getProperty(key);
          if (prop.isReadonlyToUser() || !prop.isVisibleToUser()) {
            return false;
          }

          return true;
        })
        .reduce((acc, key) => {
          acc[key] = this.model.serializedPayload[key];
          return acc;
        }, {});

      this.$nuxt.$router.push({
        path:
          this.model.getRouteSingle("new") +
          "?" +
          new URLSearchParams(defaultVals),
      });

      /*
      serverside:

      this.loading = true;
      this.$nuxt.$api
        .POST(this.model.api.path + "/" + this.model.id + "/replicate")
        .then((response) => {
          this.loading = false;
          if (!response.data) alert("Error replicating model");
          else {
            this.$nuxt.$router.push({
              path: this.model.getRouteSingle(response.data.id),
            });
          }
        });
        */
    },
  },
  computed: {
    disabled() {
      return this.model.isNew;
    },
  },
};
